import React from 'react';
import { Divider, Form, Switch, Select } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function DateSetting({ settings, onChange }) {
    return (
        <React.Fragment>
            <Divider orientation="left" plain> Date settings</Divider>
            <Form.Item label={'Set default value to current date'}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={settings?.nowAsDefault || false}
                    onChange={(value) => onChange('nowAsDefault', value)}
                />
            </Form.Item>
            <Form.Item label={'Date format'}>
                <Select
                    allowClear
                    value={settings?.dateFormat || []}
                    options={[
                        { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
                        { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
                    ]}
                    onChange={(value) => onChange('dateFormat', value)}
                />
            </Form.Item>
        </React.Fragment>
    )
}

export default DateSetting