import React from 'react';
import { Select, Space } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

const QuestionList = ({ options, questions, onChange, onDelete }) => (
    <React.Fragment>
        {options && questions.map(({ where, type, field }, index) => (
            <Space
                key={index}
                className="mb-2"
                gap={10}
                align="center"
                style={{ justifyContent: 'center', width: '100%', marginBottom: 10 }}
                size="middle"
            >
                <Select
                    style={{ minWidth: 160 }}
                    placeholder="Column"
                    value={where}
                    onChange={(value) => onChange(index, 'where', value)}
                >
                    {options.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>{label}</Select.Option>
                    ))}
                </Select>
                <Select
                    style={{ width: 120 }}
                    placeholder="Condition"
                    options={[{ value: '==', label: 'equals' }, { value: '!=', label: 'not equals' }]}
                    value={type}
                    onChange={(value) => onChange(index, 'type', value)}
                />
                <Select
                    style={{ minWidth: 160 }}
                    placeholder="Field"
                    value={field}
                    disabled={where === ""}
                    onChange={(value) => onChange(index, 'field', value)}
                >
                    {options.find(({ value }) => value === where)?.options?.map((val, index) => (
                        <Select.Option key={index} value={val}>{val}</Select.Option>
                    ))}
                </Select>
                <MinusCircleOutlined onClick={() => onDelete(index)} />
            </Space>
        ))}
    </React.Fragment>
);

export default QuestionList;
