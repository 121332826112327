import React from 'react';
import { Divider, Form, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function DependencySetting({ settings, onChange }) {
    return (
        <React.Fragment>
            <Divider orientation="left" plain> Dependency settings</Divider>
            <Form.Item label={'Single select'}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={settings?.singleSelect || false}
                    onChange={(value) => onChange('singleSelect', value)}
                />
            </Form.Item>
        </React.Fragment>
    )
}

export default DependencySetting