import axios from "axios";
import QueryError from "../utils/queryError";

const baseUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: baseUrl
});

export async function createUser(req) {
    try {
        const request = {
            account_id: req.account.id,
            id: req.id,
            name: req.name,
            email: req.email,
            picture: req.photo_original,
            country_code: req.country_code
        }
        const response = await api.post("/api/users", request);
        return response.data;
    } catch (error) {
        console.error(error);
        new QueryError({ status: 500, message: error });
    }
}

export async function createAccount(req, token) {
    try {
        const response = await api.post("/api/account/add", req, { headers: { "Content-Type": "application/json",  authorization: token } });
        return response.data;
    } catch (error) {
        console.error(error);
        new QueryError({ status: 500, message: error });
    }
}

export async function accessToken(code) {
    try {
        const response = await api.post("/api/account/getAccessToken", {
            code
        });
        return response.data;
    } catch (error) {
        console.error(error);
        new QueryError({ status: 500, message: error });
    }
}

export async function getBaord(id) {
    try {
        const response = await api.get(`/api/board/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        new QueryError({ status: 500, message: error });
    }
}

export async function createBoard(req, token) {
    try {
        const response = await api.post("/api/board/add", req, { headers: { "Content-Type": "application/json",  authorization: token } });
        return response.data;
    } catch (error) {
        console.error(error);
        new QueryError({ status: 500, message: error });
    }
}

export async function updateColumnSetting(req, token) {
    try {
        const response = await api.put("/api/form/column", req, { headers: { "Content-Type": "application/json",  authorization: token } });
        return response.data
    } catch (error) {
        new QueryError({ status: 500, message: error})
    }
}

export async function updateColumnOrder(req, token) {
    try {
        const response = await api.put("/api/form/ordering", req, { headers: { "Content-Type": "application/json",  authorization: token } })
        return response.data;
    } catch (error) {
        new QueryError({ status: 500, message: error})
    }
}

export async function updateFormLayoutSetting(req, token) {
    try {
        const response = await api.put("/api/form/setting", req, { headers: { "Content-Type": "application/json",  authorization: token } })
        return response.data;
    } catch (error) {
        new QueryError({ status: 500, message: error})
    }
}

export async function getFormData(formId) {
    try {
        const response = await api.get(`/api/form/${formId}`);
        return response.data;
    } catch (error) {
        console.error(error);
        new QueryError({ status: 500, message: error });
    }
}


export const submitMondayForm = async (sessionToken, item_name, column_values) => {
    try {
        const response = await api.post("/api/monday/submit", { session_token: sessionToken, item_name, column_values });
        return response.data;
    } catch (error) {
        console.error('Error submitting form:', error);
        return { error: 'Failed to submit form' };
    }
}

export const getAccountLogo = async (token) => {
    try {
        const response = await api.get("/api/monday/logo", { headers: { "Content-Type": "application/json",  authorization: token } });
        return response.data;
    } catch (error) {
        console.error('Error fetching token:', error);
        return { error: 'Failed to fetch token' };
    }
}

export const uploadFile = async (token, itemId, files) => {
    try {
        const response = await api.post("/api/monday/upload-file", { item_id: itemId, files }, { headers: { "Content-Type": "application/json",  authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        console.error('Error fetching token:', error);
        return { error: 'Failed to fetch token' };
    }
}


export const getBoardSubscribers = async (token) => {
    try {
        const response = await api.get(`/api/monday/subscribers`, { headers: { "Content-Type": "application/json",  authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        console.error('Error fetching subscribers:', error);
        return false;
    }
}


export const getSourceBoard = async (token, boardId, columnId) => {
    try {
        const response = await api.get(`/api/monday/source-board/${boardId}/${columnId}`, { headers: { "Content-Type": "application/json",  authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        console.error('Error fetching source board:', error);
        return false;
    }
}

export const getConnectBoard = async (token, columnId) => {
    try {
        const response = await api.get(`/api/monday/connected-board/${columnId}`, { headers: { "Content-Type": "application/json",  authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        console.error('Error fetching connected board:', error);
        return false;
    }
}

export const getBoardColumnsSetting = async (token) => {
    try {
        const response = await api.get(`/api/monday/board-str-settings`, { headers: { "Content-Type": "application/json",  authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        console.error('Error fetching board setting:', error);
        return false;
    }
}


export const submitForm = async (token, item_name, column_values) => {
    try {
        const response = await api.post("/api/monday/submit", { item_name, column_values }, { headers: { "Content-Type": "application/json",  authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        console.error('Error submitting form:', error);
        return { error: 'Failed to submit form' };
    }
}

export const updateForm = async (formId, data, token) => {
    try {
        const response = await api.put(`/api/form/${formId}`, data, { headers: { "Content-Type": "application/json",  authorization: token } });
        return response.data;
    } catch (error) {
        console.error('Error updating form:', error);
        return { error: 'Failed to update form' };
    }
}