import React from 'react';
import { Typography } from 'antd';

function ItemDescription({description, onChange }) {
    return (
        <div className='form-item-description'>
            <div className='item-description'>
                <Typography.Paragraph className='placeholder' editable={{ onChange: (value) => onChange('description', value) }}>{description || 'Add a description'}</Typography.Paragraph>
            </div>
        </div>
    )
}

export default ItemDescription;