import React from 'react';
import { Divider, Form, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function TimeSetting({ settings, onChange }) {
    return (
        <React.Fragment>
            <Divider orientation="left" plain> Hour settings</Divider>
            <Form.Item label={'Hour format'}>
                <Switch
                    checkedChildren={'24 hours'}
                    unCheckedChildren={'12 hours'}
                    checked={settings?.hoursFormat || false}
                    onChange={(value) => onChange('hoursFormat', value)}
                />
            </Form.Item>
            <Form.Item label={'Set default value to current date'}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={settings?.nowAsDefault || false}
                    onChange={(value) => onChange('nowAsDefault', value)}
                />
            </Form.Item>
        </React.Fragment>
    )
}

TimeSetting.defaultProps = {
    settings: {
        hoursFormat: false
    }
}

export default TimeSetting