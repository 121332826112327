export const columnSerializer = (columns) => {
    const avoidColumnId = new Set(["subtasks", "formula", "lookup", "doc", "mirror", "tags", "button", "location"]);

    const fields = [];
    let orderNo = 1;
    columns.forEach((item) => {
        const columnType = item?.type;
        // Only process valid items and avoid columns in the avoidColumnId list
        if (columnType && !avoidColumnId.has(columnType)) {
            fields.push({
                title: item.title ?? "", // Use ?? for nullish checks
                type: columnType,
                columnId: item.id,
                description: item.description ?? "",
                hidden: item.hidden ?? false,
                required: item.required ?? false,
                allow_update: item.allow_update ?? false,
                conditional: item.conditional ?? false,
                conditionalQuestionCondition: item.conditionalQuestionCondition ?? "",
                settings: item.settings ?? {}, // Empty object if undefined/null,
                orderNumber: item.orderNumber ?? orderNo
            })
            orderNo++;
        }
    });

    return fields; // Return the accumulated fields object
};



export const columnComparer = (columns, newColumns) => {
    if (!columns || !newColumns) {
        throw new Error("columns and newColumns are required");
    }

    const newColumnsFields = columnSerializer(newColumns);

    if (!newColumnsFields) {
        throw new Error("columnSerializer returned null or undefined");
    }

    const newObj = findNewKeys(columns, newColumnsFields);

    if (!newObj) {
        throw new Error("findNewKeys returned null or undefined");
    }

    return newObj;
}

export const columnDeletedCheck = (columns, newColumns) => {
    if (!columns || !newColumns) {
        throw new Error("columns and newColumns are required");
    }

    const newColumnsFields = columnSerializer(newColumns);

    if (!newColumnsFields) {
        throw new Error("columnSerializer returned null or undefined");
    }

    const keys1 = Object.keys(columns);
    const keys2 = Object.keys(newColumnsFields);

    const removedItems = keys1.filter(key => !keys2.includes(key));

    return removedItems;
}

const findNewKeys = (oldJson, newJson) => {
    if (!oldJson || !newJson) {
        throw new Error("oldJson and newJson are required");
    }

    const newKeys = {};

    // Loop through the keys in the new JSON
    Object.keys(newJson).forEach(key => {
        // If the key does not exist in the old JSON, add it to the newKeys object
        if (!(key in oldJson)) {
            newKeys[key] = newJson[key];
        }
    });

    return newKeys;
};


export const conditionalOptions = (columns) => {
    if (!columns) {
        throw new Error("columns is required");
    }

    const allowColumnType = ["color", "status"];

    const filteredColumns = columns.filter((column) => {
        if (!column) {
            throw new Error("column is required");
        }

        return allowColumnType.includes(column.type);
    });

    let options = [];

    filteredColumns.forEach((column) => {
        if (!column) {
            throw new Error("column is required");
        }

        let setting = JSON.parse(column.settings_str);

        if (!setting) {
            throw new Error("column.settings_str is required");
        }

        options.push({
            label: column.title,
            value: column.id,
            options: Object.values(setting.labels),
        })
    });

    return options;
}


export const convertToOptionGroup = (data) => {
    const optionGroup = {};
    data.forEach(item => {
        const workspaceName = item?.workspace?.name || "Main Workspace";
        if (!optionGroup[workspaceName]) {
            optionGroup[workspaceName] = [];
        }
        optionGroup[workspaceName].push({
            value: item.id,
            label: item.name
        });
    });
    return Object.keys(optionGroup).map(workspaceName => ({
        label: workspaceName,
        options: optionGroup[workspaceName]
    }));
}


export const columnOrdering = columns => {
    let ordering = [];
    let orderNo = 1;
    for (let index = 0; index < columns.length; index++) {
       ordering.push({
        columnId: columns[index].columnId,
        orderNumber: orderNo
       })
       orderNo++;
    }
    return ordering;
}