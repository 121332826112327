import { Form, DatePicker, Typography, TimePicker, Select } from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {conditionCheck} from '../../../helper/conditionCheck';
const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;
const DateItem = (props) => {
    const [disable, setDisable] = useState(false);
    const { columns } = props;
    const { description, conditional, conditional_question_condition, settings, type, options, optionsGroup, value } = props.other;
    const dateFormat = 'YYYY-MM-DD' || settings?.dateFormat
    const { handleValueChange } = props;
    useEffect(() => {
        if(columns){
            setDisable(conditionCheck(columns, conditional, conditional_question_condition))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[columns])

    return (
        <Form.Item name={props.name} rules={props.rules}>
            <Typography.Title level={5} style={{ margin: 0 }}>{props.title}</Typography.Title>
            {description && <Typography.Text type="secondary" style={{ fontSize: "14px", marginTop: "-5px" }}>{description}</Typography.Text>}
            {(!settings?.sourceBoard) &&
                <>
                    {type === "date" && <DatePicker placeholder={props.placeholder} title={props.title} format={settings?.dateFormat || dateFormat} size='large' style={{ width: "100%" }} value={value ? dayjs(value, (settings?.dateFormat || dateFormat)) : settings?.nowAsDefault ? dayjs() : ""} onChange={(_,info) => handleValueChange(info)} disabled={disable}/>}
                    {type === "hour" && <TimePicker placeholder={props.placeholder} title={props.title} value={(value ? dayjs(value, 'HH:mm:ss') : null) || ""} size='large' onChange={(_,info) => handleValueChange(info)} disabled={disable}/>}
                    {(type === "range" || type === "timerange" || type === "timeline") && <RangePicker placeholder={props.placeholder} title={props.title} value={value ? [dayjs(value[0], 'YYYY-MM-DD'), dayjs(value[1], 'YYYY-MM-DD')] : ""} size='large' onChange={(_,info) => handleValueChange(info)} disabled={disable}/>}
                </>
            }
            {(settings && settings?.sourceBoard) && <Select type="select" value={value|| ""} style={{ marginButtom: 0 }} className='select-option'size='large' onChange={(e) => handleValueChange(e)} disabled={disable}>
                {optionsGroup && optionsGroup.map(group => (
                    <OptGroup key={group.id} label={group.name}>
                        {group.items.map(option => (
                            <Option key={option.id} value={option.id}>
                                {option.name}
                            </Option>
                        ))}
                    </OptGroup>
                ))}
                {options && options.map(option => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
            </Select>}
        </Form.Item>
    );
}

export default DateItem;