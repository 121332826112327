import React, { useEffect } from 'react';
import { Card, Checkbox, Divider, Row, Col, Button, Typography } from 'antd';
import QuestionList from './QuestionList';
import { PlusCircleOutlined } from '@ant-design/icons';

function ConditionalQuestion({ options, conditional, conditional_question, required, onChange, children }) {
    const [questions, setQuestions] = React.useState([]);

    useEffect(() => {
        if(conditional_question) {
            setQuestions(conditional_question);
        }
    }, [conditional_question]);

    const handleAddQuestion = () => {
        setQuestions([...questions, { where: '', type: '==', field: '' }]);
    };

    const handleDeleteQuestion = (index) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    };

    const handleQuestionChange = (index, key, value) => {
        const newQuestions = [...questions];
        newQuestions[index][key] = value;
        setQuestions(newQuestions);
        if(newQuestions.length > 0) {
            const questionValid = newQuestions.filter(({ where, field }) => where && field);
            if(questionValid.length > 0){
                onChange('conditionalQuestionCondition', questionValid)
            }
        }
    };

    // React.useMemo(() => {
    //     if(questions.length > 0) {
    //         const questionValid = questions.filter(({ where, field }) => where && field);
    //         if(questionValid.length > 0){
    //             onChange('conditional_question', questionValid)
    //         }
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [questions]);

    React.useEffect(() => {
        if(!conditional) {
            handleAddQuestion()
        }
    }, []);

    return (
        <React.Fragment>
            <Divider orientation="right" plain style={{ marginTop: 0 }}>
                <div className='form-item-conditional'>
                    <Checkbox checked={conditional || false} onChange={(e) => onChange('conditional', e.target.checked)}>Conditional Question</Checkbox>
                    <Checkbox checked={required || false} onChange={(e) => onChange('required', e.target.checked)}>Required</Checkbox>
                </div>
            </Divider>
            {children}
            {conditional && (
                <Card
                    bordered={false}
                    style={{ marginTop: 16 }}
                >
                    <Row align='center' justify='space-between' gap={10}>
                        <Col span={16}>
                            <Divider orientation="left" plain style={{ marginTop: 0, width: 'calc(100% - 30px)' }} orientationMargin="0"> <Typography.Text strong>Show this field only when:</Typography.Text></Divider>
                        </Col>
                        <Col>
                            <Button type='text' icon={<PlusCircleOutlined />} onClick={() => handleAddQuestion()} />
                        </Col>
                    </Row>
                    <QuestionList
                        options={options}
                        questions={questions}
                        onChange={handleQuestionChange}
                        onDelete={handleDeleteQuestion}
                    />
                </Card>
            )}
        </React.Fragment>
    )
}

export default ConditionalQuestion