import React from "react";
import { Draggable } from "react-beautiful-dnd";

const grid = 6;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid + 5}px 0`,
    background: isDragging ? "#f1f1f1" : "rgba(0, 0, 0, 0.02)",
    borderRadius: 0,
    // boxShadow: "0 1px 2px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.1)",
    ...draggableStyle
});

const DragDropItem = (props) => {
    const { id, index, children } = props;

    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                    )}
                    className="draggable-item"
                >
                    {children}
                </div>
            )}
        </Draggable>
    );
};

export default DragDropItem;