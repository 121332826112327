import React from 'react';
import { Typography } from 'antd';
import { HighlightOutlined } from '@ant-design/icons';
import Icons from '../../utils/icons';

function ItemTitle({ label, onChange, type = 'text' }) {
    return (
        <div className='form-item-title'>
            <div className='title-icon'>
                <Icons type={type} />
            </div>
            <Typography.Text style={{ fontSize: '16px' }}  icon editable={{ icon: <HighlightOutlined />, onChange: (label) => onChange(label) }}>{label}</Typography.Text>
        </div>
    )
}

export default ItemTitle;