import React from 'react';

const Button = ({ children, type = 'button', style, onClick }) => {
  return (
    <button className='form-button' type={type} style={style} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;