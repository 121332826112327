import { CalendarOutlined, EyeOutlined, FontSizeOutlined, UnorderedListOutlined, MenuUnfoldOutlined, ApartmentOutlined, FieldNumberOutlined, SettingOutlined, ClockCircleOutlined, StarOutlined, MailOutlined, PhoneOutlined, LinkOutlined, FileOutlined, UserOutlined } from '@ant-design/icons';

import React from 'react'

const typeToIcon = {
    timeline: CalendarOutlined,
    date: CalendarOutlined,
    timerange: CalendarOutlined,
    eye: EyeOutlined,
    text: FontSizeOutlined,
    name: FontSizeOutlined,
    long_text: FontSizeOutlined,
    status: UnorderedListOutlined,
    color: UnorderedListOutlined,
    list: UnorderedListOutlined,
    dropdown: UnorderedListOutlined,
    subtasks: MenuUnfoldOutlined,
    'board_relation': ApartmentOutlined,
    'multiple-person': ApartmentOutlined,
    'single-person': ApartmentOutlined,
    'multiple-teams': ApartmentOutlined,
    'single-team': ApartmentOutlined,
    numeric: FieldNumberOutlined,
    numbers: FieldNumberOutlined,
    setting: SettingOutlined,
    hour: ClockCircleOutlined,
    rating: StarOutlined,
    email: MailOutlined,
    phone: PhoneOutlined,
    link: LinkOutlined,
    file: FileOutlined,
    doc: FileOutlined,
    user: UserOutlined,
    people: UserOutlined,
    default: UnorderedListOutlined,
}

function Icons({ type }) {
    if (!type) {
        return null;
    }

    const Icon = typeToIcon[type];
    if (!Icon) {
        console.warn(`Icon for type ${type} not found`);
        return null;
    }

    return <Icon style={{ fontSize: 18 }}/>;
}
export default Icons;