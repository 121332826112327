import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Radio, Drawer, ConfigProvider, Divider, Input, ColorPicker, Button, Select, Flex, notification } from "antd";
import { SettingOutlined, ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";
import Header from './Header';

const cols = [
    {
        label: "100%",
        value: "24"
    },
    {
        label: "75%",
        value: "16"
    },
    {
        label: "50%",
        value: "12"
    },
    {
        label: "25%",
        value: "6"
    },
]

function FormLayout({ formData, changeMode, updateFormSetting, updateColumnValue, updateLogoSetting }) {
    const [columns, setColumns] = useState({
        data: [],
        loading: true
    });

    const [setting, setSetting] = useState({});

    const [settingOpen, setSettingOpen] = useState(true);

    useEffect(() => {
        if (formData?.forms?.formSettings) {
            setSetting(formData.forms.formSettings)
        }
    }, [formData])

    useEffect(() => {
        if (formData?.forms?.formColumns) {
            const filterColumns = (data) => {
                return data.filter((column) => {
                    if (column && column.type) {
                        const type = column.type;
                        return !["subtasks", "formula", "lookup", "doc", "mirror", "tags", "button"].includes(type) || type === "location";
                    } else {
                        console.error("Invalid column data:", column);
                        return false;
                    }
                });
            }
            setColumns({
                total: formData.forms.formColumns.length,
                data: filterColumns(formData.forms.formColumns),
                loading: false
            });
        }
    }, [formData])

    const handleColWidth = (columnId, key, value) => {
        // const newColumns = [...columns.data];
        // newColumns[columnId][key] = value;
        if (updateColumnValue(columnId, { [key]: value })) {
            setColumns({ ...columns, data: columns.data.map((column) => column.columnId === columnId ? { ...column, [key]: value } : column) });
        } else {
            console.log("Error");
        }
    }

    const saveSetting = () => {
        if (updateFormSetting(setting)) {
            notification.success({
                message: 'Setting saved successfully',
            })
            setSettingOpen(false)
        } else {
            notification.error({
                message: 'Failed to save setting',
            })
        }
    }

    return (
        <ConfigProvider theme={{ token: { colorPrimary: '#1890ff', fontFamily: "Poppins", borderRadius: 4 } }}>
            {Object.keys(setting).length > 0 && <Drawer className='form-setting-drawer' title="Form Setting" placement={'left'} onClose={() => setSettingOpen(false)} open={settingOpen} mask={false} footer={<Button type="primary" block icon={<SaveOutlined />} onClick={() => saveSetting()}>Save</Button>}>
                <Divider orientation="left" style={{ borderColor: '#7cb305' }} orientationMargin="0">Logo</Divider>
                <Radio.Group defaultValue={formData?.forms?.logoShow ? "true" : "false"} size="large" onChange={(e) => updateLogoSetting({ logoShow: e.target.value })}>
                    <Radio.Button value="true">Yes</Radio.Button>
                    <Radio.Button value="false">No</Radio.Button>
                </Radio.Group>
                <Divider orientation="left" style={{ borderColor: '#7cb305' }} orientationMargin="0">Title</Divider>
                <Flex gap={10}>
                    <ColorPicker
                        allowClear
                        showText
                        defaultValue={setting?.titleSetting?.color}
                        onChangeComplete={(color) => setSetting({ ...setting, titleSetting: { ...setting?.titleSetting, color: color.toCssString() } })}
                        onChange={(color) => setSetting({ ...setting, titleSetting: { ...setting?.titleSetting, color: color.toCssString() } })}
                    />
                    <Input style={{ width: "100px" }} value={setting?.titleSetting?.fontSize.replace("px", "")} type="number" placeholder='Font size' onChange={(e) => setSetting({ ...setting, titleSetting: { ...setting?.titleSetting, fontSize: `${e.target.value}px` } })} />
                </Flex>
                <Divider orientation="left" style={{ borderColor: '#7cb305' }} orientationMargin="0">Description</Divider>
                <Flex gap={10}>
                    <ColorPicker
                        allowClear
                        showText
                        defaultValue={setting?.descSetting?.color}
                        onChangeComplete={(color) => setSetting({ ...setting, descSetting: { ...setting?.descSetting, color: color.toCssString() } })}
                        onChange={(color) => setSetting({ ...setting, descSetting: { ...setting?.descSetting, color: color.toCssString() } })}
                    />
                    <Input style={{ width: "100px" }} value={setting?.descSetting?.fontSize.replace("px", "")} type="number" placeholder='Font size' onChange={(e) => setSetting({ ...setting, descSetting: { ...setting?.descSetting, fontSize: `${e.target.value}px` } })} />
                </Flex>
                <Divider orientation="left" style={{ borderColor: '#7cb305' }} orientationMargin="0">Submit Button</Divider>
                <Row gutter={[16, 16]}>
                    <Col span={24}><Typography.Text type="secondary">Button background color</Typography.Text></Col>
                    <Col span={24}>
                        <ColorPicker
                            allowClear
                            showText
                            defaultValue={setting?.submitButtonColor}
                            onChangeComplete={(color) => setSetting({ ...setting, submitButtonColor: color.toCssString() })}
                            onChange={(color) => setSetting({ ...setting, submitButtonColor: color.toCssString() })}
                        />
                    </Col>
                    <Col span={24}><Typography.Text type="secondary">Button label color</Typography.Text></Col>
                    <Col span={24}>
                        <ColorPicker
                            allowClear
                            showText
                            defaultValue={setting?.submitButtonTextColor}
                            onChangeComplete={(color) => setSetting({ ...setting, submitButtonTextColor: color.toCssString() })}
                            onChange={(color) => setSetting({ ...setting, submitButtonTextColor: color.toCssString() })}
                        />
                    </Col>
                    <Col span={24}><Typography.Text type="secondary">Button Label</Typography.Text></Col>
                    <Col span={24}><Input value={setting?.submitButtonLabel} onChange={(e) => setSetting({ ...setting, submitButtonLabel: e.target.value })} /></Col>
                </Row>

                <Divider orientation="left" style={{ borderColor: '#7cb305' }} orientationMargin="0">Layout</Divider>
                <Radio.Group value={setting?.layout} onChange={(e) => setSetting({ ...setting, layout: e.target.value })} buttonStyle="solid">
                    <Radio.Button value="vertical">Vertical</Radio.Button>
                    <Radio.Button value="grid">Grid</Radio.Button>
                </Radio.Group>
                {setting?.layout === "grid" && <>
                    <Divider orientation="left" style={{ borderColor: '#7cb305' }} orientationMargin="0">Grid Columns</Divider>
                    <Radio.Group value={setting?.gridCol} onChange={(e) => setSetting({ ...setting, gridCol: e.target.value })}>
                        <Radio value="12">2</Radio>
                        <Radio value="8">3</Radio>
                        <Radio value="6">4</Radio>
                        <Radio value="custom">Custom</Radio>
                    </Radio.Group>
                    {setting?.gridCol === "custom" && <Divider style={{ borderColor: '#7cb305' }} orientationMargin="0">Columns</Divider>}
                    {setting?.gridCol === "custom" && columns.data?.map((column) => (
                        <>{column?.hidden === false && <Flex direction="column" align="center" justify="space-between" style={{ marginBottom: 10, padding: 5, backgroundColor: "#f5f5f5" }}>
                            <Typography.Text style={{ marginTop: 0 }}>{column?.title}</Typography.Text>
                            {setting?.gridCol === "custom" && <Select style={{ width: "70px" }} size='small' placeholder="width" options={cols} value={cols.find((col) => col.value === column?.col)} onChange={(value) => handleColWidth(column.columnId, 'col', value)} />}
                        </Flex>}</>
                    ))}
                </>}
                <Divider orientation="left" style={{ borderColor: '#7cb305' }} orientationMargin="0">Background</Divider>
                <Radio.Group value={setting?.isBackground} onChange={(e) => setSetting({ ...setting, isBackground: e.target.value })} buttonStyle="solid">
                    <Radio.Button value={false}>None</Radio.Button>
                    <Radio.Button value={true}>Background</Radio.Button>
                </Radio.Group>
                {setting?.isBackground === true && <>
                    <Divider orientation='left' style={{ borderColor: '#7cb305' }} orientationMargin="0">Background</Divider>
                    <Radio.Group value={setting?.backgroundType} onChange={(e) => setSetting({ ...setting, backgroundType: e.target.value })} buttonStyle="solid">
                        <Radio.Button value={"color"}>Color</Radio.Button>
                        <Radio.Button value={"image"}>Image</Radio.Button>
                    </Radio.Group>
                </>}
                {setting?.isBackground === true && setting?.backgroundType === "image" && <>
                    <Divider orientation="left" style={{ borderColor: '#7cb305' }} orientationMargin="0">Background Image</Divider>
                    <Input placeholder="Poster Image Url" value={setting?.backgroundImage} onChange={(e) => setSetting({ ...setting, backgroundImage: e.target.value })} />
                </>}
                {setting?.isBackground === true && setting?.backgroundType === "color" && <>
                    <Divider orientation="left" style={{ borderColor: '#7cb305' }} orientationMargin="0">Background Color</Divider>
                    <ColorPicker
                        allowClear
                        showText
                        mode={['single', 'gradient']}
                        defaultValue={setting?.backgroundColor}
                        onChangeComplete={(color) => setSetting({ ...setting, backgroundColor: color.toCssString() })}
                        onChange={(color) => setSetting({ ...setting, backgroundColor: color.toCssString() })}
                    />
                </>}
                {/* <div className='form-layout-submit'>

                </div> */}
            </Drawer>}
            {Object.keys(setting).length > 0 && <div className='form-layout'>
                <div className='form-layout-setting'>
                    <Button type="secondary" className='form-layout-export' icon={<ArrowLeftOutlined />} onClick={() => changeMode()}>Back</Button>
                    <Button type="primary" icon={<SettingOutlined />} onClick={() => setSettingOpen(true)}>Setting</Button>
                </div>
                {setting?.layout === "vertical" ? <div className='form-layout-vertical'>
                    <Header
                        layout={setting?.layout}
                        isPoster={setting?.isBackground}
                        title={formData?.forms?.formTitle}
                        titleSetting={setting?.titleSetting}
                        description={formData?.forms?.formDesc}
                        descSetting={setting?.descSetting}
                        logoShow={formData?.forms?.logoShow}
                        logo={formData?.forms?.logo}
                        backgroundType={setting?.backgroundType}
                        backgroundImage={setting?.backgroundImage}
                        backgroundColor={setting?.backgroundColor}
                    />
                    <div className='form-layout-vertical-content'>
                        {columns.data?.map((column, index) => (
                            <>{column?.hidden === false && <div key={index}>
                                <Typography.Title level={5}>{column?.title}</Typography.Title>
                                {column?.description && <p className='form-layout-vertical-description'>{column?.description}</p>}
                                <Input disabled />
                            </div>}</>
                        ))}
                        <Button type="primary" style={{ marginTop: 20 }} block>{formData?.forms?.submitButtonLabel}</Button>
                    </div>
                </div> : <div className='form-layout-grid'>
                    <div className={`form-layout-grid-container ${(setting?.isBackground === true && setting?.backgroundType === "image") ? "is--poster" : ""} ${setting?.isBackground === 1 && setting?.backgroundType === "color" ? "is--color" : ""}`}>
                        <Header
                            layout={setting?.layout}
                            isPoster={setting?.isBackground}
                            title={formData?.forms?.formTitle}
                            titleSetting={setting?.titleSetting}
                            description={formData?.forms?.formDesc}
                            descSetting={setting?.descSetting}
                            logoShow={formData?.forms?.logoShow}
                            logo={formData?.forms?.logo}
                            backgroundType={setting?.backgroundType}
                            backgroundImage={setting?.backgroundImage}
                            backgroundColor={setting?.backgroundColor}
                        />

                        <div className='form-layout-grid-content'>
                            {setting?.backgroundType === "image" && <Header
                                layout={setting?.layout}
                                isPoster={setting?.isBackground}
                                title={formData?.forms?.formTitle}
                                titleSetting={setting?.titleSetting}
                                description={formData?.forms?.formDesc}
                                descSetting={setting?.descSetting}
                                logoShow={formData?.forms?.logoShow}
                                logo={formData?.forms?.logo}
                                backgroundType={setting?.backgroundType}
                                backgroundImage={setting?.backgroundImage}
                                backgroundColor={setting?.backgroundColor}
                                onlyContent={true}
                            />}
                            <Row gutter={[16, 16]} align="bottom">
                                {columns.data?.map((column, index) => (
                                    <>{column?.hidden === false && <Col span={setting?.gridCol === "custom" ? (column?.col || 'auto') : setting?.gridCol} key={index}>
                                        <Typography.Title level={5} style={{ marginTop: 0 }}>{column?.title}</Typography.Title>
                                        {column?.description && <p className='form-layout-grid-description'>{column?.description}</p>}
                                        <Input disabled />
                                    </Col>}</>
                                ))}
                            </Row>
                            <Button type="primary" style={{ marginTop: 20, backgroundColor: setting?.submitButtonColor, color: setting?.submitButtonTextColor }} block>{setting?.submitButtonLabel || "Submit"}</Button>
                        </div>
                    </div>
                </div>}

                {/* {console.log(formData?.form)} */}

            </div>}
        </ConfigProvider>
    )
}

export default FormLayout