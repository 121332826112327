import React from "react";
import InputItem from "./Input";
import TextareaItem from "./Textarea";
import DateItem from "./Date";
import SelectItem from "./Select";
import FileItem from "./File";
import RatingItem from "./Rating";
import DropDownItem from "./DropDown";

function FormElements({
  type,
  item,
  columns,
  handleValueChange,
  handleFileValueChange,
  token,
}) {
  return (
    <React.Fragment>
      {type === "text" && (
        <InputItem
          type="text"
          title={item.title}
          name={item.columnId}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please enter ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {type === "link" && (
        <InputItem
          type="link"
          title={item.title}
          name={item.columnId}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please enter ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {type === "phone" && (
        <InputItem
          type="phone"
          title={item.title}
          name={item.columnId}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please enter ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {type === "email" && (
        <InputItem
          type="email"
          title={item.title}
          name={item.columnId}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please enter ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {type === "long_text" && (
        <TextareaItem
          type="long_text"
          title={item.title}
          name={item.columnId}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please enter ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {type === "name" && (
        <InputItem
          type="text"
          title={item.title}
          name={item.columnId}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please enter ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {type === "date" && (
        <DateItem
          type="date"
          title={item.title}
          name={item.columnId}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please select ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {(type === "numeric" || item.type === "numbers") && (
        <InputItem
          type="number"
          title={item.title}
          name={item.columnId}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please enter ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {type === "hour" && (
        <DateItem
          type="time"
          title={item.title}
          name={item.columnId}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please select ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {(type === "timeline" || item.type === "timerange") && (
        <DateItem
          type={item.type}
          title={item.title}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          name={item.columnId}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please select ${item.title.toLowerCase()}`,
            },
          ]}
        />
      )}

      {(type === "multiple-person" || type === "people") && (
        <SelectItem
          title={item.title}
          name={item.columnId}
          type="select"
          options={[]}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please select ${item.title.toLowerCase()}`,
            },
          ]}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          mode={item?.settings?.singleSelect ? false: true}
        />
      )}

      {type === "board_relation" && (
        <SelectItem
          title={item.title}
          name={item.columnId}
          type="select"
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please select ${item.title.toLowerCase()}`,
            },
          ]}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
          mode={item?.settings?.singleSelect ? false: true}
        />
      )}

      {type === "rating" && (
        <RatingItem
          title={item.title}
          name={item.columnId}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please select ${item.title.toLowerCase()}`,
            },
          ]}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
        />
      )}

      {(type === "color" || type === "status") && (
        <DropDownItem
          title={item.title}
          name={item.columnId}
          columns={columns}
          other={item}
          rules={[
            {
              required: item.required,
              message: `Please select ${item.title.toLowerCase()}`,
            },
          ]}
          handleValueChange={(value) => handleValueChange(item.columnId, value)}
        />
      )}

      {type === "file" && (
        <FileItem
          name={item.columnId}
          title={item.title}
          columns={columns}
          other={item}
          token={token}
          handleValueChange={(value) =>
            handleFileValueChange(item.columnId, value)
          }
        />
      )}
    </React.Fragment>
  );
}

export default FormElements;
