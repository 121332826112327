/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Form } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import LinkAnotherBoard from './ColumnSetting/LinkAnotherBoard';
import DateSetting from './ColumnSetting/DateSetting';
import NumericSetting from './ColumnSetting/NumericSetting';
import PersonSetting from './ColumnSetting/PersonSetting';
import TimelineSetting from './ColumnSetting/TimelineSetting';
import TimeSetting from './ColumnSetting/TimeSetting';
import DependencySetting from './ColumnSetting/DependencySetting';
import StatusSetting from './ColumnSetting/StatusSetting';
import BoardRelationSetting from './ColumnSetting/BoardRelationSetting';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 10,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
  },
};

function ItemConditionContainer({ open, boards, column, confirmLoading, handleCancel, ItemCondtionSubmit, sourceColumns, sourceBoardColumns, columns }) {
  const { columnId, type, settings } = column;
  const [items, setItems] = useState([]);
  const [updateSetting, setUpdateSetting] = useState({});

  useEffect(() => {
    if (open && settings?.conditions) {
      // console.log("conditions", settings?.conditions);
      setItems(settings?.conditions);
    } else {
      setItems([]);
    }
  }, [settings?.conditions]);

  const addCondition = useCallback(() => {
    setItems((t) => [...t, { where: '', fieldType: 'field', condition: '==', value: '' }]);
  }, []);

  const removeCondition = useCallback((index) => {
    setItems((t) => t.filter((_, i) => i !== index));
  }, []);

  const onChangeCondition = useCallback((index, key, value) => {
    // console.log("onChangeCondition", index, key, value);
    setItems((t) => {
      const newItems = [...t];
      if (newItems[index]) {
        newItems[index][key] = value;
      }
      return newItems;
    })
  }, [items]);

  const onChangeSetting = useCallback((key, value) => {
    if(key === "sourceBoard"){
      sourceColumns(value);
      setUpdateSetting((t) => ({ ...t, [key]: value, where: "", sourceColumn: "" }));
    }else{
      setUpdateSetting((t) => ({ ...t, [key]: value }));
    }
  }, []);

  useEffect(() => {
    setUpdateSetting((t) => ({ ...t, conditions: items }));
  }, [items]);

  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      setUpdateSetting(settings)
      if(settings?.sourceBoard){
        sourceColumns(settings?.sourceBoard);
      }
    } else {
      setUpdateSetting({})
    }
  }, [settings]);

  useEffect(() => {
    if (updateSetting && Object.keys(updateSetting).length > 0) {
      console.log("changed:", updateSetting);
    }
  }, [updateSetting]);


  return (
    <Modal
      open={open}
      confirmLoading={confirmLoading}
      centered
      width={800}
      title={`${column.title} settings`}
      onCancel={handleCancel}
      onOk={() => ItemCondtionSubmit(columnId, updateSetting)}
    >
      {open && <Form
        {...formItemLayout}
        style={{
          maxWidth: 800,
        }}
      >
        {type === "date" && <DateSetting settings={updateSetting} onChange={onChangeSetting} />}
        {type === "numeric" && <NumericSetting settings={updateSetting} onChange={onChangeSetting} />}
        {(type === "multiple-person" || type === "people") && <PersonSetting settings={updateSetting} onChange={onChangeSetting} />}
        {(type === "timeline" || type === "timerange") && <TimelineSetting settings={updateSetting} onChange={onChangeSetting} />}
        {type === "hour" && <TimeSetting settings={updateSetting} onChange={onChangeSetting} />}
        {type === "status" && <StatusSetting settings={updateSetting} onChange={onChangeSetting} />}
        {type === "board_relation" && <BoardRelationSetting settings={updateSetting} onChange={onChangeSetting} />}
        {(type !== "board_relation" && type !== "subtasks" && type !== "multiple-person" && type !== "hour" && type !== "timeline" && type !== "date" && type !== "people" && type !== "status") && <LinkAnotherBoard
          items={items}
          boards={boards}
          columns={columns}
          type={type}
          onChange={onChangeSetting}
          onChangeCondition={onChangeCondition}
          columnId={columnId}
          settings={updateSetting}
          addCondition={addCondition}
          removeCondition={removeCondition}
          sourceBoardColumns={sourceBoardColumns}
        />}
        {type === "dependency" && <DependencySetting settings={updateSetting} onChange={onChangeSetting} />}
      </Form>}
    </Modal>
  )
}

ItemConditionContainer.defaultProps = {
  open: false,
  confirmLoading: false,
  onChange: () => { },
  handleCancel: () => { },
  ItemCondtionSubmit: () => { },
  column: {
    columnId: '',
    type: '',
    settings: {
      conditions: [],
      distinct: false,
      sourceBoard: '',
      sourceColumn: '',
      isCustomValue: false
    }
  }
}

export default ItemConditionContainer