import React from 'react';
import { Divider, Form, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function BoardRelationSetting({ settings, onChange }) {
    return (
        <React.Fragment>
            <Divider orientation="left" plain> Connected Board Settings</Divider>
            <Form.Item label={"Single select"}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />} onChange={(e) => onChange('singleSelect', e)} checked={settings?.singleSelect || false}
                />
            </Form.Item>
        </React.Fragment>
    )
}

BoardRelationSetting.defaultProps = {
    settings: {
        singleSelect: false
    },
    onChange: () => { }
}

export default BoardRelationSetting