import React from 'react';
import { Flex, Result } from 'antd';

function AuthorizeFailed() {
    return (
        <React.Fragment>
            <Flex
                align="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <div style={{ maxWidth: 500 }}>
                    <Result
                        status="error"
                        title="Authorization Failed"
                        subTitle="Authorization Failed: You do not have the necessary permissions to access this resource or perform this action. Please contact your system administrator for assistance."
                    />
                </div>
            </Flex>
        </React.Fragment>
    )
}

export default AuthorizeFailed;