export function conditionCheck(columns, conditional, conditional_question_condition) {
    if (columns && conditional && conditional_question_condition && Array.isArray(conditional_question_condition) && conditional_question_condition.length > 0) {
        let isDisable = true;
        conditional_question_condition.forEach(item => {
            const compare = columns.find(c => c.columnId === item.where);
            if (compare && compare.value) {
                switch (item.type) {
                    case "==":
                        isDisable = compare.value !== item.field;
                        break;
                    case ">":
                        isDisable = !(compare.value > item.field);
                        break;
                    case "<":
                        isDisable = !(compare.value < item.field);
                        break;
                    case ">=":
                        isDisable = !(compare.value >= item.field);
                        break;
                    case "<=":
                        isDisable = !(compare.value <= item.field);
                        break;
                    default:
                        isDisable = true;
                        break;
                }
            } else {
                isDisable = true;
            }
        });
        return isDisable;
    }
    return false; // Return true if conditions are not met or inputs are invalid
}