import { Form, Input, InputNumber, Select, Typography } from 'antd';
import { useMemo, useCallback } from 'react';
import { conditionCheck } from '../../../helper/conditionCheck';
const { Option, OptGroup } = Select;

const InputItem = (props) => {
    const {
        columns,
        name,
        rules,
        title,
        type,
        placeholder,
        maxLength,
        value,
        min,
        max,
        handleValueChange,
        other: {
            description,
            settings,
            options,
            loading,
            optionsGroup,
            conditional,
            conditional_question_condition
        }
    } = props;

    // Memoizing the disable value
    const disable = useMemo(() => {
        if (columns) {
            return conditionCheck(columns, conditional, conditional_question_condition);
        }
        return false;
    }, [columns, conditional, conditional_question_condition]);

    // Memoizing the handleChange function to avoid redefining it on every render
    const handleChange = useCallback(
        (newValue) => {
            if (settings?.isCustomValue && newValue) {
                handleValueChange(newValue.join(', '));
            } else {
                handleValueChange(newValue);
            }
        },
        [handleValueChange, settings?.isCustomValue]
    );

    return (
        <Form.Item name={name} rules={rules}>
            <Typography.Title level={5} style={{ margin: 0 }}>{title}</Typography.Title>
            {description && <Typography.Text type="secondary" style={{ fontSize: "14px", marginTop: "-5px" }}>{description}</Typography.Text>}
            {((type === 'text' || type === 'link' || type === 'phone' || type === 'email') && !settings?.sourceBoard) &&
                <Input
                    type={type === 'link' ? 'link' : type === 'phone' ? 'tel' : type === 'email' ? 'email' : 'text'}
                    placeholder={placeholder}
                    title={title}
                    value={value}
                    maxLength={maxLength}
                    size='large'
                    onChange={(e) => handleChange(e.target.value)}
                    disabled={disable}
                />}
            {(type === 'number' && !settings?.sourceBoard) &&
                <InputNumber
                    placeholder={placeholder}
                    title={title}
                    value={value}
                    min={min}
                    max={max}
                    style={{ width: "100%" }}
                    size='large'
                    onChange={(e) => handleChange(e)}
                    disabled={disable}
                />}
            {settings?.sourceBoard &&
                <Select
                    type="select"
                    value={value}
                    style={{ marginButtom: 0 }}
                    className='select-option'
                    loading={loading}
                    size='large'
                    onChange={(e) => handleChange(e)}
                    disabled={disable}
                    mode={settings?.singleSelect ? "" : (settings?.isCustomValue ? 'tags' : 'default')}
                >
                    {optionsGroup && optionsGroup.map(group => (
                        <OptGroup key={group.id} label={group.name}>
                            {group.items.map((option, index) => (
                                <Option key={index} value={option.id || option.value}>
                                    {option.name}
                                </Option>
                            ))}
                        </OptGroup>
                    ))}
                    {options && options.map((option, index) => (
                        <Option key={index} value={option.id || option.value}>{option.label}</Option>
                    ))}
                </Select>}
        </Form.Item>
    );
};

export default InputItem;
