import React, { useState, useEffect, useCallback } from "react";
import {
    Flex,
    Row,
    Col,
    Card,
    ConfigProvider,
    Divider,
    Button,
    Popover,
} from "antd";
import Title from "../UI/Title";
import ActionButton from "../UI/Button";
import DragDropCard from "./FormElements/DragDropCard";
import {
    BoxPlotOutlined,
    ShareAltOutlined,
    ExportOutlined,
} from "@ant-design/icons";
import copyToClipboard from 'elastic-copy-paste';
import ItemSettingModal from "../UI/ItemSettingModal";
// import {sample} from '../../static/testfile';/

function FormBuilder({
    titleConfig,
    changeMode,
    boards,
    columns_serialized,
    condition_options,
    sourceColumns,
    sourceBoardColumns,
    handleTitleChange,
    formButton,
    callColumnSetting,
    updateColumnValue,
    formId,
    onChangeOrdering
}) {
    const [columnSetting, setColumnSetting] = useState(false);
    const [columns, setColumns] = useState({
        data: [],
        loading: true,
    });

    const [itemSetting, setItemSetting] = useState("");

    useEffect(() => {
        setColumns({
            data: columns_serialized,
            loading: false,
        });
    }, [columns_serialized]);

    const handleOpenSetting = (column) => {
        setItemSetting(column);
        setColumnSetting(true);
    };

    const handleItemSettingSubmit = (columnId, updateSetting) => {
        // console.log("updateSetting", updateSetting);
        const isUpdate = callColumnSetting(columnId, updateSetting)
        if (isUpdate) {
            setColumns((prev) => ({
                ...prev,
                data: prev.data.map((column) =>
                    column.columnId === columnId
                        ? { ...column, settings: updateSetting }
                        : column
                ),
            }));
            setColumnSetting(false);
        }
    };

    const handleColumnValueChange = useCallback(
        (columnId, key, value) => {
            // console.log(columnId, key, value);
            if (columns?.data) {
                const updatedColumns = columns.data.map((column) =>
                    column.columnId === columnId ? { ...column, [key]: value } : column
                );
                const isUpdate = updateColumnValue(columnId, { [key] : value })
                if(isUpdate){
                    setColumns((prev) => ({ ...prev, data: updatedColumns }));
                }
            } else {
                console.error("Invalid state, cannot update column value");
            }
        },
        [columns]
    );

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#1890ff",
                    fontFamily: "Poppins",
                    borderRadius: 4,
                },
            }}
        >
            <div className="form-builder-container">
                <Flex justify="center" align="center">
                    <Card className="form-builder-card">
                        <Row>
                            <Col span={16}>
                                <Title
                                    {...titleConfig}
                                    onChange={(key, value) => handleTitleChange(key, value)}
                                />
                            </Col>
                            <Col span={8}>
                                <Flex gap="small" justify="end" align="center" wrap>
                                    <Popover title="Form layout setting">
                                        <Button
                                            icon={<BoxPlotOutlined />}
                                            type="primary"
                                            title="Form Layout Setting"
                                            onClick={() => changeMode()}
                                        />
                                    </Popover>
                                    {formId && <Popover title="Form preview">
                                        <Button
                                            icon={<ExportOutlined />}
                                            type="primary"
                                            title="Preview"
                                            target="_blank" href={`${process.env.REACT_APP_HOST}form/${formId}`}
                                        />
                                    </Popover>}
                                    {formId && <Button icon={<ShareAltOutlined />} onClick={() => copyToClipboard(`${process.env.REACT_APP_HOST}form/${formId}`).then(() => alert("Link copied to clipboard"))}>Share</Button>}
                                </Flex>
                            </Col>
                        </Row>
                        <Divider />
                        {columns.loading ? (
                            <p>Loading...</p>
                        ) : (
                            <DragDropCard
                                columns={columns.data}
                                condition_options={condition_options}
                                openSetting={(data) => handleOpenSetting(data)}
                                onChange={(columnId, column, value) =>
                                    handleColumnValueChange(columnId, column, value)
                                }
                                onChangeOrdering={(ordering) => onChangeOrdering(ordering)}
                            />
                        )}
                        <ActionButton
                            type="button"
                            children={formButton.submitButtonLabel}
                            style={{
                                width: "100%",
                                marginTop: "20px",
                                backgroundColor: formButton.submitButtonColor,
                                color: formButton.submitButtonTextColor,
                            }}
                        />
                    </Card>
                </Flex>
            </div>
            <ItemSettingModal
                column={itemSetting}
                open={columnSetting}
                boards={boards}
                handleCancel={() => setColumnSetting(false)}
                ItemCondtionSubmit={(columnId, updateSetting) =>
                    handleItemSettingSubmit(columnId, updateSetting)
                }
                sourceBoardColumns={sourceBoardColumns}
                sourceColumns={(boardId) => sourceColumns(boardId)}
                columns={columns_serialized}
            />
        </ConfigProvider>
    );
}

export default FormBuilder;
