import { Form, Input, Select, Typography } from 'antd';
import { useEffect, useState } from 'react';
import {conditionCheck} from '../../../helper/conditionCheck';
const { Option, OptGroup } = Select;

const TextareaItem = (props) => {
    const { columns } = props;
    const { description, settings, options, loading, optionsGroup, conditional, conditional_question_condition } = props.other;
    const [disable, setDisable] = useState(false);
    const { handleValueChange } = props;
    useEffect(() => {
        if(columns){
            setDisable(conditionCheck(columns, conditional, conditional_question_condition))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[columns])

    return (
        <Form.Item name={props.name} rules={props.rules}>
            <Typography.Title level={5} style={{ margin: 0 }}>{props.title}</Typography.Title>
            {description && <Typography.Text type="secondary" style={{ fontSize: "14px", marginTop: "-5px" }}>{description}</Typography.Text>}
            {(props.type === 'long_text' && !settings?.sourceBoard) &&
                <Input.TextArea placeholder={props.placeholder} title={props.title} value={props.value} size='large' onChange={(e) => handleValueChange(e.target.value)} disabled={disable} maxLength={2000} showCount/>}
            {(settings?.sourceBoard) && <Select type="select" value={props.value} style={{ marginButtom: 0 }} className='select-option' loading={loading} size='large' onChange={(e) => handleValueChange(e)} disabled={disable}>
                {optionsGroup && optionsGroup.map(group => (
                    <OptGroup key={group.id} label={group.name}>
                        {group.items.map((option, index) => (
                            <Option key={index} value={option.id || option.value}>
                                {option.name}
                            </Option>
                        ))}
                    </OptGroup>
                ))}
                {options && options.map((option, index) => (
                    <Option key={index} value={option.id || option.value}>{option.label}</Option>
                ))}
            </Select>}
        </Form.Item>
    );
}

export default TextareaItem;