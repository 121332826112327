import { InboxOutlined } from '@ant-design/icons';
import React from 'react';
import {
    Form,
    Upload,
    message,
    Typography
} from 'antd';

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};

const FileItem = ({ title, description, name, token, handleValueChange }) => {
    const props = {
        name: 'file',
        multiple: false,
        action: process.env.REACT_APP_API_URL + 'api/monday/upload',
        headers: {
            Authorization: `Bearer ${token}`
        },
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done' && info.file.response) {
            try {
                handleValueChange(info.file.response.data.path)
            } catch (error) {
                console.log(error);
            }
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        }
      };

    return (
        <Form.Item>
            <Typography.Title level={5} style={{ margin: 0 }}>{title}</Typography.Title>
            {description && <Typography.Text type="secondary" style={{ fontSize: "14px", marginTop: "-5px" }}>{description}</Typography.Text>}
            <Form.Item name={name} valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                <Upload.Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag files to this area to upload</p>
                </Upload.Dragger>
            </Form.Item>
        </Form.Item>
    )
}

export default FileItem