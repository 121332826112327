import mondaySdk from "monday-sdk-js";
import QueryError from '../utils/queryError';
import axios from "axios";

const monday = mondaySdk();

export async function runQuery({ query, variables }) {
    try {
        const response = await monday.api(query, { variables });

        if (response.errors) {
            throw new QueryError({
                status: 400,
                message: response.errors.map(error => error.message),
                error_code: "MONDAY_API_ERROR"
            });
        }

        if (response.error_code) {
            if (response.error_code === "ComplexityException") {
                // Wait for 60 seconds and retry the query
                await new Promise(resolve => setTimeout(resolve, 60 * 1000));
                return runQuery({ query, variables });
            }

            throw new QueryError({
                status: response.status_code || 500,
                message: response.error_message,
                error_code: response.error_code
            });
        }

        if (response.error_message) {
            throw new QueryError({
                status: response.status_code || 500,
                message: response.error_message,
                error_code: "MONDAY_API_ERROR"
            });
        }

        return response.data;

    } catch (error) {
        throw new Error(`Failed to execute query: ${error.message}`);
    }
}

export async function getAuthorizationInformation() {
    const data = await runQuery({
        query: `query { me { id account { slug } } }`,
        variables: {}
    });
    return {
        userId: data.me.id,
        slug: data.me.account.slug
    };
}

export async function getMe() {
    const data = await runQuery({
        query: `{
            me {
                account {
                    id name slug logo plan { tier max_users }
                    first_day_of_the_week
                    country_code
                }
                id name email photo_original country_code
            }
        }`,
        variables: {}
    });
    return data.me;
}

export async function getUser(userId) {
    const data = await runQuery({
        query: `query ($userId: [ID!]) {
            users (ids: $userId) {
                id name email photo_original country_code
            }
        }`,
        variables: { userId }
    });
    return data.users[0];
}

export async function getBoard(boardId) {
    const data = await runQuery({
        query: `query ($boardId: [ID!]) {
            boards (ids: $boardId) {
                id name description
                columns { id type title description settings_str }
                groups { id title color position }
                owners { id name email }
            }
        }`,
        variables: { boardId }
    });
    return data.boards[0];
}

export async function getBoards({ boardIds, limit, page, includeWorkspace }) {
    return await runQuery({
        query: `query ($boardIds: [ID!], $limit: Int, $page: Int, $includeWorkspace: Boolean!) {
            boards (ids: $boardIds, limit: $limit, page: $page) {
                id name
                workspace @include(if: $includeWorkspace) {
                    id name
                }
            }
        }`,
        variables: {
            boardIds,
            limit,
            page,
            includeWorkspace: includeWorkspace || false
        }
    });
}

export async function getBoardColumns({ boardId, columnIds = [] }) {
    return await runQuery({
        query: `query ($boardIds: [ID!], $columnIds: [String]) {
            boards(ids: $boardIds) {
                columns(ids: $columnIds) {
                    id title type settings_str
                }
            }
        }`,
        variables: { boardIds: [boardId], columnIds }
    });
}

export async function getColumnsSettings({ boardId, columnId }) {
    const columnsData = await getBoardColumns({
        boardId,
        columnIds: [columnId]
    });

    const column = columnsData.boards[0].columns.find(c => c.id === columnId);

    if (!column) {
        throw new QueryError({
            status: 400,
            message: "Column not found",
            error_code: "COLUMN_NOT_FOUND"
        });
    }

    return JSON.parse(column.settings_str);
}


export async function getAccessToken(code, client_id, client_secret) {
    if (!code || !client_id || !client_secret) {
        throw new Error("getAccessToken: Missing required parameter");
    }

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth`, {
            code,
            client_id,
            client_secret
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            throw new QueryError({
                status: 401,
                message: "Invalid authorization code, client_id, or client_secret",
                error_code: "INVALID_AUTHORIZATION"
            });
        }
        throw error;
    }
}
