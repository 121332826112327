import React from 'react';
import { Divider, Form, InputNumber } from 'antd';

function NumericSetting({ settings, onChange }) {
    return (
        <React.Fragment>
            <Divider orientation="left" plain> Number settings</Divider>
            <Form.Item label={"Minimum value"}>
                <InputNumber value={settings?.minimumValue} onChange={(value) => onChange('minimumValue', value)} />
            </Form.Item>
            <Form.Item label={"Maximum value"}>
                <InputNumber value={settings?.maximumValue} onChange={(value) => onChange('maximumValue', value)} />
            </Form.Item>
        </React.Fragment>
    )
}

export default NumericSetting