import { Form, Typography, Rate } from 'antd';
import { useEffect, useState } from 'react';
import {conditionCheck} from '../../../helper/conditionCheck';

const RatingItem = (props) => {
    const [disable, setDisable] = useState(false);
    const { columns } = props;
    const { description, rules, handleValueChange } = props;
    const { conditional, conditional_question_condition } = props.other;

    useEffect(() => {
        if(columns){
            setDisable(conditionCheck(columns, conditional, conditional_question_condition))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[columns])

    return (
        <Form.Item name={props.name} rules={rules}>
            <Typography.Title level={5} style={{margin: 0}}>{props.title}</Typography.Title>
            {description && <Typography.Text type="secondary" style={{ fontSize: "14px", marginTop: "-5px"}}>{description}</Typography.Text>}
            <Rate defaultValue={0} value={props.value} onChange={(e) => handleValueChange(e)} disabled={disable}/>
        </Form.Item>
    );
}

export default RatingItem;