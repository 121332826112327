import React, { useEffect, useState, useRef } from 'react';
import { ConfigProvider, Form, Flex, Typography, Row, Col, Button, notification, Image } from 'antd';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Header from '../components/FormLayout/Header';
import FormElements from '../components/Form/FormElements';


import { getFormData, getBoardSubscribers, getSourceBoard, getConnectBoard, getBoardColumnsSetting, submitForm, uploadFile } from '../api/backend';

function UserForm() {
  let { formId } = useParams();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [formData, setformData] = useState({
    data: {},
    loading: true
  });
  const [setting, setsetting] = useState();
  const [columns, setcolumns] = useState([]);
  const [token, settoken] = useState("");
  const [boardRawColumns, setboardRawColumns] = useState();
  const [formSubmit, setformSubmit] = useState(false);
  const [sourceBoards, setSourceBoards] = useState({});
  const [relationBoards, setrelationBoards] = useState({});
  const [dropdownColumn, setDropdownColumn] = useState({});
  const [personsId, setPersonsId] = useState([]);
  const [persons, setPersons] = useState([]);
  const [files, setFiles] = useState([]);

  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message
    });
  };

  useEffect(() => {
    if (formId) {
      initFromData(formId)
    }
  }, [formId])

  const initFromData = (formId) => {
    getFormData(formId).then((res) => {
      setsetting(res.data.formSettings);
      handleFormColumn(res.data.formColumns);
      // setcolumns(res.data.formColumns);
      setformData({
        data: res.data
      });
      settoken(res.token);
      getBoardColumns(res.token);
      getBoardSubscriber(res.token);
    })
  }

  const getBoardColumns = async (token) => {
    try {
      const data = await getBoardColumnsSetting(token);
      if (data && data?.columns) {
        setboardRawColumns(data.columns)
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const handleChangeValue = (columnId, value) => {
    try {
      form.setFieldValue(columnId, value)
      setcolumns((prevColumns) =>
        prevColumns.map((column) =>
          column.columnId === columnId
            ? { ...column, value }
            : column
        )
      );
    } catch (error) {
      console.log("handleChangeValue:", error);
    }
  };

  const handleFileValueChange = (columnId, value) => {
    setFiles((prevFiles) => ({ ...prevFiles, [columnId]: value }));
  }

  const handleFormColumn = (data) => {
    let formColumns = [];
    let sourceBoards = [];
    let relationBoards = [];
    let dropdownColumn = [];
    let person = [];

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const columnId = data[key]['columnId'];
        const columnData = data[key];

        // Push form columns, adding a loading field for certain types
        if (columnData.settings?.sourceBoard || columnData['type'] === "board_relation" || columnData['type'] === "color") {
          formColumns.push({ ...columnData, columnId, loading: true });
        } else {
          formColumns.push({ ...columnData, columnId });
        }

        // Push to sourceBoards array if conditions match
        if (columnData['type'] !== "board_relation" && columnData['settings'] && columnData['settings']['sourceBoard']) {
          sourceBoards.push({
            columnId,
            sourceBoard: columnData['settings']['sourceBoard'],
            sourceColumn: columnData['settings']['sourceColumn'],
          });
        }
        // Push to relationBoards array for "board_relation" type
        if (columnData['type'] === "board_relation") {
          relationBoards.push({ columnId });
        }

        // Push to dropdownColumn array for "color" or "status" types
        if (columnData['type'] === "color" || columnData['type'] === "status") {
          dropdownColumn.push(columnId);
        }

        // Push to person array for "multiple-person" or "people" types
        if (columnData['type'] === "multiple-person" || columnData['type'] === "people") {
          person.push(columnId);
        }
      }
    }

    // Update the state with arrays
    setDropdownColumn(dropdownColumn);
    setcolumns(formColumns);
    setSourceBoards(sourceBoards);
    setrelationBoards(relationBoards);
    setPersonsId(person);
    setformData({ ...formData, loading: false });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (sourceBoards) {
        for (const key in sourceBoards) {
          const columnId = sourceBoards[key]['columnId'];
          const sourceBoard = sourceBoards[key]['sourceBoard'];
          const sourceColumn = sourceBoards[key]['sourceColumn'];
          await getSourceBoardData(token, sourceBoard, sourceColumn, columnId);
        }
      }
    };

    fetchData(); // Call the async function directly inside useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceBoards]);


  useEffect(() => {
    const fetchData = async () => {
      if (relationBoards) {
        for (const key in relationBoards) {
          const columnId = relationBoards[key]['columnId'];
          await connectedBoarddata(token, columnId);
        }
      }
    };
    fetchData(); // Call the async function directly inside useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationBoards]);

  useEffect(() => {
    if (dropdownColumn && boardRawColumns) {
      const dropdownColumns = boardRawColumns.filter(column => dropdownColumn.includes(column.id));
      dropdownColumns.forEach((col) => {
        let dropdown = JSON.parse(col.settings_str)?.labels;
        setcolumns((prevColumns) =>
          prevColumns.map((column) =>
            column.columnId === col.id
              ? { ...column, options: Object.values(dropdown), loading: false }
              : column
          )
        );
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardRawColumns, dropdownColumn]);

  useEffect(() => {
    if (personsId && persons) {
      personsId.forEach((personId) => {
        setcolumns((prevColumns) =>
          prevColumns.map((column) =>
            column.columnId === personId
              ? { ...column, options: persons, loading: false }
              : column
          )
        );
      })
    }
  }, [personsId, persons])

  const getSourceBoardData = async (token, boardId, columnId, targetId) => {
    try {
      const data = await getSourceBoard(token, boardId, columnId);
      if (data) {
        setcolumns((prevColumns) =>
          prevColumns.map((column) =>
            column.columnId === targetId
              ? { ...column, options: data, loading: false }
              : column
          )
        );
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };


  const connectedBoarddata = async (token, columnId) => {
    try {
      const data = await getConnectBoard(token, columnId);
      if (data) {
        setcolumns((prevColumns) =>
          prevColumns.map((column) =>
            column.columnId === columnId
              ? { ...column, optionsGroup: data, loading: false }
              : column
          )
        );
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const getBoardSubscriber = async (token) => {
    try {
      const data = await getBoardSubscribers(token);
      if (data) {
        setPersons(data)
      } else {
        console.error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  }

  const onFinish = async (values) => {
    const valuesArray = Object.keys(values).map((key) => ({ id: key, value: values[key] }));
    if (valuesArray) {
      let column_values = {};
      let item_name = ""
      valuesArray.forEach((item) => {
        const column = columns.find((column) => column.columnId === item.id);
        if (column.type === "name" && item.value) {
          item_name = item.value
        }
        if ((column.type === "text" || column.type === "long_text" || column.type === "phone") && item.value) {
          column_values[item.id] = item.value
        }
        if (column.type === "email" && item.value) {
          column_values[item.id] = { "email": item.value, "text": item.value }
        }
        if (column.type === "link" && item.value) {
          column_values[item.id] = { "url": item.value, "text": item.value }
        }
        if ((column.type === "numeric" || column.type === "numbers") && item.value) {
          column_values[item.id] = item.value
        }
        if (column.type === "date" && item.value) {
          column_values[item.id] = dayjs(item.value).format('YYYY-MM-DD');
        } else if (column.settings && column.settings.nowAsDefault) {
          column_values[item.id] = dayjs().format('YYYY-MM-DD');
        }
        if (column.type === "hour" && item.value) {
          const [hh, mm, ss] = item.value.split(":");
          column_values[item.id] = { "hour": parseInt(hh), "minute": parseInt(mm) }
        }
        if (column.type === "range" && item.value) {
          column_values[item.id] = { "from": dayjs(item.value[0]).format('YYYY-MM-DD'), "to": dayjs(item.value[1]).format('YYYY-MM-DD') }
        }
        if (column.type === "timerange" && item.value) {
          column_values[item.id] = { "from": dayjs(item.value[0]).format('YYYY-MM-DD'), "to": dayjs(item.value[1]).format('YYYY-MM-DD') }
        }
        if (column.type === "timeline" && item.value) {
          column_values[item.id] = { "from": dayjs(item.value[0]).format('YYYY-MM-DD'), "to": dayjs(item.value[1]).format('YYYY-MM-DD') }
        }
        if (column.type === "location" && item.value) {
          column_values[item.id] = item.value
        }
        if (column.type === "rating" && item.value) {
          column_values[item.id] = { "rating": parseInt(item.value) }
        }

        // if(column.type === "dropdown" && item.value){
        //     column_values[item.id] = { "labels" : [item.value] }
        // }

        if (column.type === "multiple-person" && item.value) {
          column_values[item.id] = item.value
        }

        if (column.type === "board_relation" && item.value) {
          column_values[item.id] = { "item_ids": typeof item.value !== "object" ? [item.value] : item.value }
        }

        if (column.type === "people" && item.value) {
          column_values[item.id] = item.value
        }
        if (column.type === "color" && item.value) {
          column_values[item.id] = { "label": item.value }
        }
        if (column.type === "status" && item.value) {
          column_values[item.id] = { "label": item.value }
        }
      })

      if (!item_name) {
        openNotificationWithIcon('error', `Please enter name`);
        return
      }
      if (Object.keys(column_values).length === 0) {
        openNotificationWithIcon('error', 'Please enter form values');
        return
      }
      setformSubmit(true)
      const result = await submitForm(token, item_name, column_values);

      if (result.status) {
        if (Object.keys(files).length > 0) {
          await uploadFile(token, result.data.id, files);
        }
        if (formData.formSubmitOnClear) {
          setFiles([]);
          form.resetFields();
          resetValueKey()
        }
        setformSubmit(false)
        openNotificationWithIcon('success', formData.submitSuccessMsg);
      } else {
        setformSubmit(false)
        openNotificationWithIcon('error', 'Form submission failed');
      }

    }
  }

  const resetValueKey = () => {
    setcolumns((prevColumns) =>
      prevColumns.map((column) => ({
        ...column,
        value: "" // Reset the value
      }))
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    if (errorInfo.errorFields) {
      errorInfo.errorFields.forEach((error) => {
        form.setFields([
          {
            name: error.name,
            errors: [error.errors[0]],
          },
        ]);
      })
    }
  };


  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Poppins",
        },
      }}
    >
      <HelmetProvider>
        <Helmet>
          {formData.loading ? null : <title>{formData?.data?.formTitle || "Form"}</title>}
        </Helmet>
      </HelmetProvider>
      {contextHolder}
      <div
        style={{
          minHeight: "100vh",
          background: "#f1f1f1",
          padding: "40px 0",
        }}
      >
        {formData.loading ? null : <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className='form-layout'>
            {setting?.layout === "vertical" ? <div className='form-layout-vertical'>
              <Header
                layout={setting?.layout}
                isPoster={setting?.isBackground}
                title={formData?.data?.formTitle}
                titleSetting={setting?.titleSetting}
                description={formData?.data?.formDesc}
                descSetting={setting?.descSetting}
                logoShow={formData?.data?.logoShow}
                logo={formData?.data?.logo}
                backgroundType={setting?.backgroundType}
                backgroundImage={setting?.backgroundImage}
                backgroundColor={setting?.backgroundColor}
              />
              <div className='form-layout-vertical-content'>
                {columns?.map((column, index) => (
                  <div key={index}>
                    <FormElements type={column?.type} item={column} columns={columns} handleValueChange={handleChangeValue} handleFileValueChange={handleFileValueChange} token={token} />
                  </div>
                ))}
                <Button type="primary" htmlType="submit" size='large' style={{ marginTop: 20, color: setting?.submitButtonTextColor, backgroundColor: setting?.submitButtonColor }} block>{setting?.submitButtonLabel}</Button>
              </div>
            </div> : <div className='form-layout-grid'>
              <div className={`form-layout-grid-container ${(setting?.isBackground === true && setting?.backgroundType === "image") ? "is--poster" : ""} ${setting?.isBackground === true && setting?.backgroundType === "color" ? "is--color" : ""}`}>
                <Header
                  layout={setting?.layout}
                  isPoster={setting?.isBackground}
                  title={formData?.data?.formTitle}
                  titleSetting={setting?.titleSetting}
                  description={formData?.data?.formDesc}
                  descSetting={setting?.descSetting}
                  logoShow={formData?.data?.logoShow}
                  logo={formData?.data?.logo}
                  backgroundType={setting?.backgroundType}
                  backgroundImage={setting?.backgroundImage}
                  backgroundColor={setting?.backgroundColor}
                />

                <div className='form-layout-grid-content'>
                  {setting?.backgroundType === "image" && <Header
                    layout={setting?.layout}
                    isPoster={setting?.isBackground}
                    title={formData?.data?.formTitle}
                    titleSetting={setting?.titleSetting}
                    description={formData?.data?.formDesc}
                    descSetting={setting?.descSetting}
                    logoShow={formData?.data?.logoShow}
                    logo={formData?.data?.logo}
                    backgroundType={setting?.backgroundType}
                    backgroundImage={setting?.backgroundImage}
                    backgroundColor={setting?.backgroundColor}
                    onlyContent={true}
                  />}
                  <Row gutter={[16, 16]} align="bottom">
                    {columns?.map((column, index) => (
                      <Col span={setting?.gridCol === "custom" ? (column?.row || 'auto') : setting?.gridCol} key={index}>
                        {/* type, item, columns, handleValueChange, handleFileValueChange, token */}
                        <FormElements type={column?.type} item={column} columns={columns} handleValueChange={handleChangeValue} handleFileValueChange={handleFileValueChange} token={token} />
                      </Col>
                    ))}
                  </Row>
                  <Button type="primary" htmlType="submit" size='large' style={{ marginTop: 20, color: setting?.submitButtonTextColor, backgroundColor: setting?.submitButtonColor }} block>{setting?.submitButtonLabel}</Button>
                </div>
              </div>
            </div>}
          </div>
        </Form>}
      </div>
    </ConfigProvider>
  )
}

export default UserForm