import React from 'react';
import { Divider, Form, Select } from 'antd';

function TimelineSetting({ settings, onChange }) {
    return (
        <React.Fragment>
            <Divider orientation="left" plain> Timeline settings</Divider>
            <Form.Item label={'Date format'}>
                <Select
                    value={settings?.dateFormat || []}
                    options={[
                        { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
                        { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
                    ]}
                    onChange={(value) => onChange('dateFormat', value)}
                />
            </Form.Item>
        </React.Fragment>
    )
}

TimelineSetting.defaultProps = {
    settings: {
        dateFormat: 'DD/MM/YYYY'
    }
}

export default TimelineSetting