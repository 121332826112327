import React from 'react';
import { Divider, Form, Select, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function StatusSetting({ settings, onChange }) {
    return (
        <React.Fragment>
            <Divider orientation="left" plain> Status settings</Divider>
            <Form.Item label={"Checkbox option to show"}>
                <Select showSearch placeholder={"Select a label"} value={settings?.checkboxOptions || []} onChange={(e) => onChange('checkboxOptions', e)} options={settings?.options || []} allowClear />
            </Form.Item>
            <Form.Item label={"Checkbox view"}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={settings?.checkboxView || false} onChange={(e) => onChange('checkboxView', e)}
                />
            </Form.Item>
        </React.Fragment>
    )
}


StatusSetting.defaultProps = {
    settings: {
        checkboxView: false,
        checkboxOptions: [],
        options: []
    }
}

export default StatusSetting