import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UserForm from './pages/userFrom';
import FormCreate from "./pages/formCreate";
import Auth from "./pages/auth";
import AuthorizeFailed from "./pages/authorizeFailed";


const App = () => {
  return (
    <Router basename={"/form-builder"}>
      <Routes>
        {/* {attentionBoxText} */}
        <Route path="/" element={<FormCreate />} />
        <Route path="/form/:formId" element={<UserForm />} />
        <Route path="/auth/callback" element={<Auth />} />
        <Route path="/authorize-failed" element={<AuthorizeFailed />} />
      </Routes>
    </Router>
  );
};

export default App;
