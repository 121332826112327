import { Flex, Typography } from 'antd';
import React from 'react';
import { SettingOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import ItemTitle from '../../UI/ItemTitle';
import ItemDescription from '../../UI/ItemDescription';
import ConditionalQuestion from '../../UI/ConditionalQuestion';


function FormItem({ label, description, condition_options, conditional, conditional_question, hidden, required, onChange, type = 'text', openSetting, handlehidden }) {
    return (
        <div className="form-item">
            <Flex justify='space-between' align='center'>
                <ItemTitle label={label} onChange={(value) => onChange('title', value)} type={type} description={description} />
                {!hidden && <div className='form-item-setting'>
                    <Typography.Link onClick={() => handlehidden('hidden', !hidden)}><EyeOutlined style={{ fontSize: 20 }} /></Typography.Link>
                    {(type !== 'file' && type !== 'rating' && type !== 'dropdown' && type !== 'multiple-person') && (
                        <Typography.Link onClick={() => openSetting()}>
                            <SettingOutlined style={{ fontSize: 20 }} />
                        </Typography.Link>
                    )}
                </div>}
                {hidden && <div className='form-item-setting'>
                    <Typography.Link onClick={() => handlehidden('hidden', !hidden)}><EyeInvisibleOutlined style={{ fontSize: 20 }} /></Typography.Link>
                </div>}
            </Flex>
            {!hidden && <ConditionalQuestion options={condition_options} conditional={conditional} conditional_question={conditional_question} required={required} onChange={onChange}>
                <ItemDescription description={description} onChange={onChange} />
            </ConditionalQuestion>}
        </div>
    )
}

export default FormItem