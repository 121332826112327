import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { accessToken } from '../api/backend';
import { Spin, Flex, Result } from "antd";

function Auth(props) {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [processMsg, setProcessMsg] = useState("Authorizing...");
    const [Error, setError] = useState("");

    useEffect(() => {
        const code = searchParams.get('code');
        const region = searchParams.get('region');
        const scope = searchParams.get('scope');
        const state = searchParams.get('state');
        console.log('code:', code, 'region:', region, 'scope:', scope, 'state:', state);
        if (code) {
            handleToken(code)
        } else {
            setError("Failed to get access token");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleToken = async (code) => {
        setProcessMsg('Authorizing');
        const data = await accessToken(code);
        if (data && data?.status) {
            navigate(`/`);
        } else {
            setError("Failed to get access token");
            handleRedirect()
        }
    }

    const handleRedirect = () => {
        // Redirect to a different URL
        navigate('/authorize-failed');
    };

    return (
        <React.Fragment>
            <Flex justify="center" align="center" style={{ height: "100vh" }}>
                <Flex gap="small">
                    {!Error &&
                        <Spin tip={processMsg} size="large"/>
                    }
                    {Error &&
                        <Result
                            status="error"
                            title="Authorization Failed"
                            subTitle={Error}
                        />
                    }
                </Flex>
            </Flex>
        </React.Fragment>
    )
}

export default Auth;