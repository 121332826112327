import React, { useState, useEffect, useCallback } from 'react';
import FormBuilder from '../components/FormBuilder/FormBuilder';
import FormLayout from '../components/FormLayout';
import mondaySdk from "monday-sdk-js";
import { columnSerializer, columnComparer, columnDeletedCheck, conditionalOptions, convertToOptionGroup } from "../helper/columns";
import { Flex, Alert, Spin } from 'antd';

import {
    getMe,
    getBoard,
    getBoards,
} from '../api/monday';

import {
    createAccount, createBoard, updateColumnSetting, updateColumnOrder, updateFormLayoutSetting, updateForm
} from "../api/backend";

const monday = mondaySdk();
function FormCreate() {
    const [context, setContext] = useState();
    const [formId, setFormId] = useState("");
    const [mainBoardId, setMainBoardId] = useState("");
    const [sessionToken, setSessionToken] = useState("");
    const [accountLogo, setAccountLogo] = useState("");
    const [mondayError, setMondayError] = useState("");
    const [formMode, setformMode] = useState("create");
    const [boards, setboards] = useState([]);
    const [account, setaccount] = useState({});
    const [titleConfig, settitleConfig] = useState({});
    const [formButton, setformButton] = useState({
        submitButtonColor: "#1677ff",
        submitButtonTextColor: "#ffffff",
        submitButtonLabel: "Submit",
    });
    const [board, setboard] = useState({});
    const [conditionOptions, setConditionOptions] = useState([]);
    const [loading, setloading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [sourceBoardColumns, setSourceBoardColumns] = useState({ data: [], loading: false });
    const [accountDetail, setAccountDetail] = useState({ loading: true, data: {} });
    const [formData, setformData] = useState({});

    useEffect(() => {
        monday.execute("valueCreatedForUser");

        monday.listen("context", (res) => {
            setContext(res.data);
            if (!mainBoardId) {
                setMainBoardId(res.data.boardIds);
            }
        });

        if (!sessionToken) {
            monday.api(`{
              me {
                account {
                  logo
                }
              }
            }`)
                .then(response => {
                    if (!response) {
                        setMondayError("Error retrieving data!");
                        return false;
                    }
                    setAccountLogo(response?.data?.me?.account?.logo);
                })
                .catch(error => {
                    setMondayError("Error retrieving data!");
                    console.error('Error retrieving account logo:', error);
                });
            monday.get("sessionToken").then((res) => {
                if (res?.data) {
                    setSessionToken(res.data);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (mainBoardId) {
            const mondayData = async () => {
                // const { userId, slug } = await getAuthorizationInformation();
                const me = await getMe();
                // const user = await getUser(userId);
                const boards = await getBoards({ boardIds: null, includeWorkspace: true, limit: 1000, page: 1 });
                const board = await getBoard(mainBoardId);
                // const columns = await getBoardColumns({ boardId: mainBoardId });
                setaccount(me);
                setboard(board);
                setboards(convertToOptionGroup(boards.boards));
            };
            mondayData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainBoardId]);

    useEffect(() => {
        const callBoardData = async () => {
            if (board && board?.columns) {
                let columns_serialized = columnSerializer(board.columns)
                // setColumns(columns_serialized);
                setConditionOptions(conditionalOptions(board.columns));
                // clientId, userId, accountId, appId, logo, slug, userEmail, userName, isAdmin
                const { app, user } = context;
                const me = account;
                await handleAccount(app?.clientId, user?.id, me?.account?.id, app?.id, me?.account?.logo, me?.account?.slug, me?.email, me?.name, user?.isAdmin, columns_serialized);
            }
        }
        callBoardData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [board]);

    const sourceColumns = async (boardId) => {
        if (!boardId) {
            return;
        }
        try {
            setSourceBoardColumns({ data: [], loading: true });
            const board = await getBoard(boardId);
            if (!board || !board.columns) {
                throw new Error("board or board.columns is null or undefined");
            }
            setSourceBoardColumns({ data: board.columns, loading: false });
        } catch (error) {
            console.error("Error fetching source columns", error);
            setSourceBoardColumns({ data: [], loading: false });
        }
    }

    const handleAccount = (clientId, userId, accountId, appId, logo, slug, userEmail, userName, isAdmin, columns) => {
        createAccount({ clientId, userId, accountId, appId, logo, slug, userEmail, userName, isAdmin }, sessionToken).then((res) => {
            if (res && res.status) {
                setAccountDetail({ loading: false, data: res.data });
                if (!res.data.permanentToken) {
                    window.location.href = 'https://auth.monday.com/oauth2/authorize?client_id=993861d206b52572e72c38da65f45898';
                } else {
                    let boardViewId = context.boardViewId || context.instanceId;
                    const { id, name } = board;
                    let formData = {
                        formTitle: name,
                        formDesc: "",
                        logoShow: false,
                        logo: logo,
                    }
                    handleBoardForm(res.data.id, id, boardViewId, name, formData, columns)
                }
            }
        })
    }

    const handleBoardForm = async (accountId, boardId, boardViewId, boardName, formData, formColumns) => {
        if (!boardId || !accountDetail || !boardViewId || !boardName || !formData || !formColumns) {
            setMondayError("Something went worng!")
            return;
        }

        try {
            const res = await createBoard({ accountId, boardId, boardViewId, boardName, formData, formColumns }, sessionToken);
            if (res?.status) {
                const { forms, forms: { formSettings } } = res.data;
                settitleConfig({
                    formTitle: forms.formTitle,
                    titleColor: formSettings.titleSetting.color,
                    formDesc: forms.formDesc,
                    subtitleColor: formSettings.descSetting.color
                });
                setformButton({
                    submitButtonColor: formSettings.submitButtonColor,
                    submitButtonTextColor: formSettings.submitButtonTextColor,
                    submitButtonLabel: formSettings.submitButtonLabel
                });
                setColumns(forms.formColumns);
                setformData(res.data);
                setFormId(forms.id);
                setloading(false);
            }
        } catch (error) {
            console.error("Error handling board form:", error);
        }
    }

    const handleTitleChange = useCallback(async (key, value) => {
        try {
            const res = await updateForm(formData.forms.id, { [key]: value }, sessionToken);
            if (res?.status) {
              settitleConfig((prev) => ({ ...prev, [key]: value }));
            }
          } catch (error) {
            console.error("Error updating form title:", error);
          }
    },[formData, sessionToken]);

    const callColumnSetting = (columnId, setting) => {
        let data = {
            columnId,
            settings: setting,
            formId: formData.forms.id
        }
        return updateColumnSetting(data, sessionToken).then((res) => {
            return res.status;
        }).catch((error) => {
            console.log("ErrorMsg:", error);
        })
    }

    const updateColumnValue = (columnId, value) => {
        let data = {
            columnId,
            formId: formData.forms.id, ...value
        }
        return updateColumnSetting(data, sessionToken).then((res) => {
            return res.status;
        }).catch((error) => {
            console.log("ErrorMsg:", error);
        })
    }

    const updateFormSetting = (data) => {
        return updateFormLayoutSetting({ formId: formData.forms.id, settings: data }, sessionToken).then((res) => {
            let titleSetting = data.titleConfig;
            let descSetting = data.descConfig;
            settitleConfig({ title: formData.forms.formTitle, titleColor: titleSetting.color, formDesc: formData.forms.formDesc, subtitleColor: descSetting.color });
            setformButton({ submitButtonColor: data.submitButtonColor, submitButtonTextColor: data.submitButtonTextColor, submitButtonLabel: data.submitButtonLabel });
            return res.status;
        }).catch((error) => {
            console.log("ErrorMsg:", error);
            return false;
        })
    }

    const onChangeOrdering = (ordering) => {
        return updateColumnOrder({ formId: formData.forms.id, columns: ordering }, sessionToken).then((res) => {
            return res;
        }).catch((error) => {
            console.log("ErrorMsg:", error);
            return false;
        })
    }

    const updateLogoSetting = (data) => {
        return updateForm(formData.forms.id, { logoShow: data.logoShow }, sessionToken).then((res) => {
            if (res?.status) {
                setformData({ ...formData, forms: { ...formData.forms, logoShow: data.logoShow } });
            }
        }).catch((error) => {
            console.log("ErrorMsg:", error);
            return false;
        })
    }

    return (
        <React.Fragment>
            {loading && (
                <div className="monday-loading">
                    <Flex justify="center" align="center" style={{ height: "100vh" }}>
                        <Flex gap="small">
                            <Spin size="large" />
                        </Flex>
                    </Flex>
                </div>
            )}
            {!loading && mondayError && (
                <div className="monday-error">
                    <Alert message={mondayError} type="error" showIcon />
                </div>
            )}
            {!loading && formMode === "create" && (
                <FormBuilder
                    titleConfig={titleConfig}
                    changeMode={() => setformMode("layout")}
                    accountLogo={accountLogo}
                    boardId={mainBoardId}
                    boards={boards}
                    account={account}
                    board={board}
                    columns_serialized={columns}
                    condition_options={conditionOptions}
                    sourceColumns={sourceColumns}
                    sourceBoardColumns={sourceBoardColumns}
                    handleTitleChange={handleTitleChange}
                    formButton={formButton}
                    callColumnSetting={callColumnSetting}
                    formId={formId}
                    updateColumnValue={(columnId, value) => updateColumnValue(columnId, value)}
                    onChangeOrdering={(data) => onChangeOrdering(data)}
                />
            )}
            {!loading && formMode === "layout" && (
                <FormLayout changeMode={() => setformMode("create")} formData={formData} updateFormSetting={(data) => updateFormSetting(data)} updateColumnValue={(columnId, value) => updateColumnValue(columnId, value)} updateLogoSetting={(data) => updateLogoSetting(data)} />
            )}
        </React.Fragment>
    )
}

export default FormCreate;