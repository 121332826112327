import React from 'react';
import { Divider, Form, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function PersonSetting({ settings, onChange }) {
    return (
        <React.Fragment>
            <Divider orientation="left" plain> Person settings</Divider>
            <Form.Item label={'Single select'}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={settings?.singleSelect || false}
                    onChange={(value) => onChange('singleSelect', value)}
                />
            </Form.Item>
            <Form.Item label={'Show guests'}>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={settings?.showGuests || false}
                    onChange={(value) => onChange('showGuests', value)}
                />
            </Form.Item>
        </React.Fragment>
    )
}

PersonSetting.defaultProps = {
    settings: {
        singleSelect: false,
        showGuests: false
    },
    onChange: () => { }
}

// PersonSetting.propTypes = {
//     settings: Object,
//     onChange: Function
// }

export default PersonSetting