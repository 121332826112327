import React from 'react';
import { Select, Typography, Flex, Space, Button, Input, Divider, Form, Switch } from 'antd';
import { CloseCircleOutlined, PlusOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
const { Option, OptGroup } = Select;


const style = { width: "175px" }
const fieldOptions = [{ value: "field", label: "Field" }, { value: "value", label: "Value" }];
const avoidSourceColumn = ["subtasks", "board-relation", "doc", "link", "lookup", "formula", "list", "rating", "dropdown", "multiple-person", "location", "tags", "hour", "timerange", "rating", "file", "timeline", "long_text"];
const avaidColumnType = ["subtasks", "board-relation", "doc", "link", "lookup", "formula", "list", "rating", "dropdown", "multiple-person", "location", "tags", "hour", "timerange", "rating", "file", "timeline"];

export default function LinkAnotherBoard({ boards, columns, sourceBoardColumns, items, addCondition, removeCondition, onChangeCondition, settings, onChange }) {
  console.log(boards);

  return (
    <React.Fragment>
      <Divider orientation="left" plain> Select values from another board</Divider>
      <Form.Item label={"Board to fetch from"}>
        <Select showSearch placeholder={"Select a board"} title={"Board to fetch from"} value={settings?.sourceBoard} onChange={(value) => onChange('sourceBoard', value)} allowClear>
          {boards && boards.map(group => (
            <OptGroup key={group.label} label={group.label}>
              {group.options.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={"Column to fetch from"}>
        <Select showSearch placeholder={"Select a column"} value={settings?.sourceColumn} loading={sourceBoardColumns.loading} onChange={(value) => onChange('sourceColumn', value)} title={"Column to fetch from"} allowClear>
          {sourceBoardColumns.data?.filter(({ type }) => !avoidSourceColumn.includes(type)).map(({ id, title }) => (
            <Option key={id} value={id}>
              {title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={"Remove duplications (Distinct)"}>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={settings?.distinct || false}
          onChange={(value) => onChange('distinct', value)}
        />
      </Form.Item>
      <Form.Item label={"Custom value enable"}>
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={settings?.isCustomValue || false}
          onChange={(value) => onChange('isCustomValue', value)}
        />
      </Form.Item>
      {settings?.sourceBoard && (
        <>
          <Divider orientation="left" plain>Conditional filtering</Divider>
          {items.map((item, index) => (
            <Space size="middle" style={{ marginBottom: 10 }} key={index}>
              <div style={{ width: "50px" }}>
                <Typography.Text>{index === 0 ? "Where" : "And"}</Typography.Text>
              </div>
              <Flex gap={7} vertical={true}>
                <Select showSearch placeholder={"Subitem field"} value={item.where} onChange={(value) => onChangeCondition(index, "where", value)} style={style}>
                  {sourceBoardColumns.data?.filter(({ type }) => !avaidColumnType.includes(type)).map(({ id, title }) => (
                    <Option key={id} value={id}>
                      {title}
                    </Option>
                  ))}
                </Select>
              </Flex>
              <Typography.Text style={{ marginBottom: "5px" }}>equals </Typography.Text>
              <div >
                {item.fieldType === "value" && <Input placeholder='Value' value={item.value} onChange={(e) => onChangeCondition(index, "value", e.target.value)} style={style} disabled={!item.where} />}
                {item.fieldType === "field" && <Select showSearch placeholder="Field" value={item.value} onChange={(value) => onChangeCondition(index, "value", value)} allowClear style={style} disabled={!item.where}>
                  {columns.filter(({ type }) => !avaidColumnType.includes(type)).map(({ columnId, title }) => (
                    <Select.Option key={columnId} value={columnId}>
                      {title}
                    </Select.Option>
                  ))}
                </Select>}
              </div>
              <div>
                <Select defaultValue={["field"]} value={item.fieldType || ["field"]} options={fieldOptions} onChange={(value) => onChangeCondition(index, "fieldType", value)} variant="borderless" style={{ width: "100px" }} />
              </div>
              <CloseCircleOutlined onClick={() => removeCondition(index)} />
            </Space>
          ))}
          <Button type="dashed" block onClick={addCondition}>
            <PlusOutlined /> Add condition
          </Button>
        </>
      )}
    </React.Fragment>
  )
}
