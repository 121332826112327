class QueryError extends Error {
    constructor({ status, message, error_code }) {
        super(message);  // Call the parent class (Error) constructor with the error message
        this.name = 'QueryError';  // Optional: Set the error name to differentiate from other errors
        this.status = status;      // Status code (e.g., 400, 500)
        this.error_code = error_code;  // Specific error code from the API (e.g., "MONDAY_API_ERROR")
    }
}


module.exports = QueryError;