import React, { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import DragDropItem from './DragDropItem';
import FormItem from './FormItem';
import { columnOrdering } from '../../../helper/columns';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

function DragDropCard({ columns, condition_options, openSetting, onChange, onChangeOrdering }) {
    const [items, setItems] = useState(columns)
    const onDragEnd = useCallback(result => {
        if (!result.destination) {
            return;
        }
        const updatedItems = reorder(items, result.source.index, result.destination.index);
        // console.log("updated:",updatedItems);
        const oldItems = items;
        setItems(updatedItems);
        let ordering = columnOrdering(updatedItems);
        if(ordering){
            onChangeOrdering(ordering).then((res) => {
                if(!res.status){
                    setItems(oldItems);
                }
            }).catch((error) => {
                setItems(oldItems);
            })
        }

    }, [items, onChangeOrdering]);

    useEffect(() => {
        if (columns) {
            setItems(columns);
        }
    }, [columns]);


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {items.map((val, index) => (
                            <DragDropItem
                                key={val.columnId}
                                id={val.columnId}
                                index={index}
                            >
                                <FormItem
                                    label={val.title}
                                    columnId={val.columnId}
                                    type={val.type}
                                    description={val.description}
                                    hidden={val.hidden}
                                    required={val.required}
                                    condition_options={condition_options}
                                    conditional={val.conditional}
                                    conditional_question={val.conditionalQuestionCondition}
                                    settings={val.settings}
                                    onChange={(column, value) => onChange(val.columnId, column, value)}
                                    openSetting={() => openSetting(val)}
                                    handlehidden={(column, value) => onChange(val.columnId, column, value)}
                                />
                            </DragDropItem>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DragDropCard