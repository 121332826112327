import React, { useState, useEffect } from 'react';
import { Form, Select, Typography } from 'antd';
import {conditionCheck} from '../../../helper/conditionCheck';
const { Option, OptGroup } = Select;

const DropDownItem = (props) => {
    const [disable, setDisable] = useState(false);
    const { columns, description, loading, handleValueChange} = props;
    const { optionsGroup, options, value, conditional, conditional_question_condition } = props.other

    useEffect(() => {
        if(columns){
            setDisable(conditionCheck(columns, conditional, conditional_question_condition))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[columns])


    return (
        <Form.Item name={props.name} rules={props.rules} className='select-option'>
            <Typography.Title level={5} style={{margin: 0}}>{props.title}</Typography.Title>
            {description && <Typography.Text type="secondary" style={{ fontSize: "14px", marginTop: "-5px" }}>{description}</Typography.Text>}
            <Select type="select" value={value || ""} style={{ marginButtom: 0 }} className='select-option' loading={loading} size='large' onChange={(e) => handleValueChange(e)} disabled={disable}>
                {optionsGroup && optionsGroup.map(group => (
                    <OptGroup key={group.id} label={group.name}>
                        {group.items.map(option => (
                            <Option key={option.id} value={option.id}>
                                {option.name}
                            </Option>
                        ))}
                    </OptGroup>
                ))}
                {options && options.map(option => (
                    <Option key={option} value={option}>{option}</Option>
                ))}
            </Select>
        </Form.Item>
    );
}

export default DropDownItem;