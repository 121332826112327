import React, { useEffect, useState } from 'react';
import { Form, Select, Typography } from 'antd';
import {conditionCheck} from '../../../helper/conditionCheck';
const { Option, OptGroup } = Select;
let timeout;
let currentValue;
const options = [];
for (let i = 10; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
}

const fetchData = (value, callback) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;
    const board = () => {
        fetch(`https://suggest.taobao.com/sug?${value}`)
            .then((response) => response.json())
            .then((d) => {
                if (currentValue === value) {
                    const { result } = d;
                    const data = result.map((item) => ({
                        value: item[0],
                        text: item[0],
                    }));
                    callback(data);
                }
            });
    };
    if (value) {
        timeout = setTimeout(board, 300);
    } else {
        callback([]);
    }
};

const SelectItem = (props) => {
    const [disable, setDisable] = useState(false);
    const { columns, mode, description, loading, handleValueChange } = props;
    const { optionsGroup, options, conditional, conditional_question_condition, value } = props.other
    const [data, setData] = useState([]);

    const handleSearch = (newValue) => {
        fetchData(newValue, setData);
    };

    useEffect(() => {
        if(columns){
            setDisable(conditionCheck(columns, conditional, conditional_question_condition))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[columns])

    return (
        <Form.Item name={props.name} rules={props.rules} className='select-option'>
            {/* {console.log(options)} */}
            <Typography.Title level={5} style={{margin: 0}}>{props.title}</Typography.Title>
            {description && <Typography.Text type="secondary" style={{ fontSize: "14px", marginTop: "-5px"}}>{description}</Typography.Text>}
            {props.type === 'select' && mode && <Select type="select" value={value} style={{ marginButtom: 0 }} className='select-option' loading={loading} size='large' onChange={(e) => handleValueChange(e)} disabled={disable}>
                {optionsGroup && optionsGroup.map(group => (
                    <OptGroup key={group.id} label={group.name}>
                        {group.items_page && group.items_page.items.map(option => (
                            <Option key={option.id} value={option.id}>
                                {option.name}
                            </Option>
                        ))}
                    </OptGroup>
                ))}
                {options && options.map(option => (
                    <Option key={option.value || option.id} value={option.value || option.id}>{option.label || option.name}</Option>
                ))}
            </Select>}
            {props.type === 'select' && !mode && <Select type="select" value={value} style={{ marginButtom: 0 }} className='select-option' loading={loading} size='large' onChange={(e) => handleValueChange(e)} disabled={disable} mode={'multiple'}>
                {optionsGroup && optionsGroup.map(group => (
                    <OptGroup key={group.id} label={group.name}>
                        {group.items_page && group.items_page.items.map(option => (
                            <Option key={option.id} value={option.id}>
                                {option.name}
                            </Option>
                        ))}
                    </OptGroup>
                ))}
                {options && options.map(option => (
                    <Option key={option.value || option.id} value={option.value || option.id}>{option.label || option.name}</Option>
                ))}
            </Select>}
            {props.type === 'selectRemote' && <Select showSearch placeholder={props.placeholder} title={props.title} onSearch={handleSearch}
                value={value}
                size='large'
                defaultActiveFirstOption={false}
                notFoundContent={null} mode={props.mode}
                options={(data || []).map((d) => ({
                    value: d.value,
                    label: d.text,
                }))}
                onChange={(e) => handleValueChange(e)}
                disabled={disable}
            />}
        </Form.Item>
    );
}

export default SelectItem