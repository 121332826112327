import React from 'react';
import { Typography } from 'antd';

function Header({ layout, isPoster, title, titleSetting, description, descSetting, logoShow, logo, backgroundType, backgroundImage, backgroundColor, onlyContent = false }) {
    const renderLogo = () => logoShow && <img src={logo} alt="logo" className={`form-layout-${layout}-logo`} />;
    const renderContent = () => (
        <div className='form-header-content'>
            {renderLogo()}
            <Typography.Title level={4} style={titleSetting}>{title}</Typography.Title>
            <Typography.Text type="secondary" style={descSetting}>{description}</Typography.Text>
        </div>
    );

    if (onlyContent && backgroundType === "image") return renderContent();

    const backgroundStyle = backgroundType === "image"
        ? { backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }
        : { backgroundColor: backgroundColor };

    return (
        <React.Fragment>
            {layout === "vertical" && (
                <>
                    {isPoster === 0 ? (
                        <div className='form-layout-vertical-header'>
                            {renderContent()}
                        </div>
                    ) : (
                        <div className='form-layout-vertical-poster' style={backgroundStyle}>
                            <div className='form-layout-vertical-poster-title' style={{ minHeight: 120, display: "flex", alignItems: "flex-start", justifyContent: "center", width: "100%", flexDirection: "column" }}>
                                {renderContent()}
                            </div>
                        </div>
                    )}
                </>
            )}

            {layout === "grid" && (
                <>
                    {isPoster === 0 ? (
                        <div className='form-layout-grid-header'>
                            {renderContent()}
                        </div>
                    ) : (
                        <>
                            {(backgroundType === "color") && (
                                <div className='form-layout-grid-poster' style={backgroundStyle}>
                                    <div className='form-layout-grid-poster-title color-panel'>
                                        {renderContent()}
                                    </div>
                                </div>
                            )}
                            {backgroundType === "image" && (
                                <div className='form-layout-grid-poster' style={backgroundStyle}/>
                            )}
                        </>
                    )}
                </>
            )}
        </React.Fragment>
    );
}

export default Header;
