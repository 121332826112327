import { Input } from 'antd';
import React, { useState } from 'react'

function Title({ formTitle, formDesc, titleColor, subtitleColor, onChange }) {
    const [titleEdit, setTitleEdit] = useState(false);
    const [subtitleEdit, setSubtitleEdit] = useState(false);
    const [titleValue, setTitleValue] = useState(formTitle);
    const [subtitleValue, setSubtitleValue] = useState(formDesc);

    const handleTitleChange = ({ target: { value } }) => setTitleValue(value);
    const handleSubtitleChange = ({ target: { value } }) => setSubtitleValue(value);

    const handleTitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setTitleEdit(false);
            onChange('formTitle', titleValue);
        }
    };

    const handleSubtitleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setSubtitleEdit(false);
            onChange('formDesc', subtitleValue);
        }
    };

    return (
        <div className='form-title-section'>
            <div className='form-title-card'>
                <h1
                    style={{ color: titleColor }}
                    onClick={() => setTitleEdit(true)}
                    onBlur={() => setTitleEdit(false)}
                >
                    {titleEdit ? (
                        <Input
                            type="text"
                            value={titleValue}
                            onChange={handleTitleChange}
                            onKeyDown={handleTitleKeyDown}
                            autoFocus
                        />
                    ) : (
                        titleValue
                    )}
                </h1>
                <p
                    style={{ color: subtitleColor }}
                    onClick={() => setSubtitleEdit(true)}
                    onBlur={() => setSubtitleEdit(false)}
                >
                    {subtitleEdit ? (
                        <Input
                            type="text"
                            value={subtitleValue}
                            onChange={handleSubtitleChange}
                            onKeyDown={handleSubtitleKeyDown}
                            autoFocus
                        />
                    ) : (
                        subtitleValue || 'Create your form'
                    )}
                </p>
            </div>
        </div>
    );
}

export default Title